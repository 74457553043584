import React, { useState, useEffect,useRef } from 'react';
import './css/EstadioBarranquillaSumas.css';  // Asegúrate de que los estilos estén correctamente vinculados
import guacamayoBG from '../guacamayoBG.png';
function EstadioBarranquillaSumas({ avatar,onNext  }) {
  const [selectedNumbers, setSelectedNumbers] = useState([[], [], []]); // Tres bloques con dos espacios cada uno
    const [validatedBlocks, setValidatedBlocks] = useState([false, false, false]); // Rastrear bloques yvalidados
const hasSpokenRef = useRef(false); // Controlar si ya ha hablado
const utteranceRef = useRef(null); // Referencia al SpeechSynthesisUtterance para detener el audio
  const [avatarPosition] = useState('start'); // Para animar el avatar
  const correctSum = 168275; // El valor correcto que debe tener cada bloque

  useEffect(() => {
  
  


    // Sonido de inicio de la escena
    const audio = new Audio('/audio/pito.mp3');
    audio.play().catch((error) => {
    console.error('Error al iniciar la reproducción:', error);
  });
    const speak = (text) => {
      if (hasSpokenRef.current) return;
      const utterance = new SpeechSynthesisUtterance(text);
      utteranceRef.current = utterance;
      // Obtener todas las voces disponibles
      const voices = window.speechSynthesis.getVoices();

      // Buscar una voz en español (Latinoamérica)
      const vozLatina = voices.find(voice => voice.lang === 'es-MX' || voice.lang === 'es-US' || voice.lang === 'es-ES');

      // Si se encuentra una voz en español, asignarla
      if (vozLatina) {
        utterance.voice = vozLatina;
      }

      // Reproducir el mensaje
      window.speechSynthesis.speak(utterance);
      hasSpokenRef.current = true;
      // Al terminar de hablar, mover el avatar hacia el centro
      
    };speak('Organiza tres parejas diferentes de números para que, al sumarlos, den como resultado 168275');
  }, []);

  const handleDragStart = (e, number) => {
    e.dataTransfer.setData('number', number); // Guardar el número arrastrado
  };

  const handleDrop = (e, blockIndex, boxIndex) => {
    const number = parseFloat(e.dataTransfer.getData('number')); // Obtener el número arrastrado
    const newSelectedNumbers = [...selectedNumbers];
    console.log(newSelectedNumbers);
    const emptyIdx = newSelectedNumbers[blockIndex].findIndex((val) => val === undefined);


    if (newSelectedNumbers[blockIndex].length < 2 || emptyIdx !== -1) {
      newSelectedNumbers[blockIndex][boxIndex] = number;
      console.log(newSelectedNumbers);
      setSelectedNumbers(newSelectedNumbers);
      console.log(setSelectedNumbers);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Permitir que el número sea soltado
  };
  
 useEffect(() => {
    // Recorre todos los bloques para verificar si hay dos números en alguno
    selectedNumbers.forEach((block,blockIndex) => {
      if (block.length === 2 && !validatedBlocks[blockIndex] && block[0]!==undefined) {
        const sum = block.reduce((acc, curr) => acc + curr, 0); // Sumar los valores del bloque
        if (sum === correctSum) {
          // Reproducir sonido de éxito
          const successAudio = new Audio('/audio/correct.mp3');
          successAudio.play();
          const newValidatedBlocks = [...validatedBlocks];
          newValidatedBlocks[blockIndex] = true;
          setValidatedBlocks(newValidatedBlocks);
        } else {
          // Reproducir sonido de error
          const errorAudio = new Audio('/audio/error.mp3');
          errorAudio.play();
        }
      }
    }); }, [selectedNumbers, validatedBlocks]);

  const validateSums = () => {
    let valid = true;

    for (let i = 0; i < selectedNumbers.length; i++) {
      const sum = selectedNumbers[i].reduce((acc, curr) => acc + curr, 0); // Sumar los valores de cada bloque
      if (sum !== correctSum) {
        valid = false;
        break;
      }
    }

    if (valid) {
    if (utteranceRef.current) {
      window.speechSynthesis.cancel(); // Detener el audio si se está reproduciendo
    }
    const successAudio = new Audio('/audio/success.mp3');
    successAudio.play();
      alert('¡Prueba superada! Las sumas son correctas.');
      onNext(); // Llamar a la función para completar la escena
    } else {
      alert('Una o más sumas son incorrectas. Por favor, inténtalo de nuevo.');
      setValidatedBlocks([false,false,false]);
      setSelectedNumbers([[], [], []]); // Vaciar los cuadros para volver a intentar
    }
  };

  return (
    <div className="escena-barranquilla">
      {/* Agregar el guacamayo volando */}
      <div className="dialog-box">
        <img src={guacamayoBG} alt="Guacamayo" className="guacamayoBG" />
        <p>Organiza tres parejas diferentes de números para que, al sumarlos, den como resultado 168.275</p>
      </div>

      {/* Mostrar el avatar */}
      <img
        src={`/image/${avatar}.png`}
        alt="Avatar del Jugador"
        className={`avatar ${avatarPosition}`}
      />

      <div className="numeros-arrastrables">
        <div className="numero" draggable onDragStart={(e) => handleDragStart(e, 142876)}>142.876</div>
        <div className="numero" draggable onDragStart={(e) => handleDragStart(e, 49574)}>49.574</div>
        <div className="numero" draggable onDragStart={(e) => handleDragStart(e, 39576)}>39.576</div>
        <div className="numero" draggable onDragStart={(e) => handleDragStart(e, 118701)}>118.701</div>
        <div className="numero" draggable onDragStart={(e) => handleDragStart(e, 128699)}>128.699</div>
        <div className="numero" draggable onDragStart={(e) => handleDragStart(e, 25399)}>25.399</div>
      </div>

      <div className="bloques-suma">
        {selectedNumbers.map((block, blockIndex) => (
          <div key={blockIndex} className="bloque">
            <div className="cuadro" onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, blockIndex, 0)}>
              {block[0]}
            </div>
            <div className="simbolo">+</div>
            <div className="cuadro" onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, blockIndex, 1)}>
              {block[1]}
            </div>
          </div>
        ))}
      </div>

      <button onClick={validateSums} className="boton-validar">
        Validar sumas
      </button>
    </div>
  );
}

export default EstadioBarranquillaSumas;


