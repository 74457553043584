import React, { useState,useRef,useEffect } from 'react';
import './css/escena14.css';
import guacamayo from '../guacamayoBG.png'; // Imagen del guacamayo
import estadioCali from '../estadioCali.webp'; // Imagen de la cancha de fondo
import guacamayoImage from '../guacamayoBG.png';
const EscenaMultiplicacion = ({ avatar , onNext }) => {
  const [isCorrect, setIsCorrect] = useState(false);
  const hasSpokenRef = useRef(false); // Controlar si ya ha hablado
  const utteranceRef = useRef(null);
  
   const [multiplicaciones, setMultiplicaciones] = useState({
    "156": "R", "260": "E", "368": "L", "528": "N", 
    "120": "O", "320": "T", "450": "Q", "108": "M",
    "180": "S", "300": "C", "285": "Y", "460": "I", 
    "390": "U"
  });
  const [inputValues, setInputValues] = useState({
    input1: '',
    input2: '',
    input3: '',
    input4: '',
    input5: '',
    input6: '',
    input7: '',
    input8: '',
    input9: '',
    input10: '',
    input11: '',
    input12: '',
    input13: '',
    input14: '',
    input15: '',
    input16: '',
    input17: '',
    input18: '',
    input19: '',
    input20: '',
    input21: '',
    input22: '',
    input23: '',
    input24: '',
    input25: '',
  });
  
   const correctAnswers = {
    input1: 'c',
    input2: 'r',
    input3: 'e',
    input4: 'e',
    input5: 'e',
    input6: 'n',
    input7: 't',
    input8: 'i',
    input9: 'm',
    input10: 'i',
    input11: 's',
    input12: 'm',
    input13: 'o',
    input14: 'y',
    input15: 'e',
    input16: 'n',
    input17: 'l',
    input18: 'o',
    input19: 'q',
    input20: 'u',
    input21: 'e',
    input22: 'e',
    input23: 'r',
    input24: 'e',
    input25: 's',
    
  };
  
    useEffect(() => {
  
  


    // Sonido de inicio de la escena
    const speak = (text) => {
      if (hasSpokenRef.current) return;
      const utterance = new SpeechSynthesisUtterance(text);
      utteranceRef.current = utterance;
      // Obtener todas las voces disponibles
      const voices = window.speechSynthesis.getVoices();

      // Buscar una voz en español (Latinoamérica)
      const vozLatina = voices.find(voice => voice.lang === 'es-MX' || voice.lang === 'es-US' || voice.lang === 'es-ES');

      // Si se encuentra una voz en español, asignarla
      if (vozLatina) {
        utterance.voice = vozLatina;
      }

      // Reproducir el mensaje
      window.speechSynthesis.speak(utterance);
      hasSpokenRef.current = true;
      // Al terminar de hablar, mover el avatar hacia el centro
      
    };speak('Descubre el mensaje secreto, al desarrollar bien las multiplicaciones. el resultado corresponde a una letra con la cual en el orden que están escritas las operaciones, forma una frase, debes determinar cuál es.');
  }, []);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Actualiza el valor del input correspondiente en el objeto inputValues
    setInputValues({
      ...inputValues,
      [name]: value.toLowerCase().trim(),
    });
  };
  
   const handleSubmit = () => {
    let allCorrect = true;

    // Verificar cada input con su valor correcto
    for (let key in inputValues) {
      if (inputValues[key] !== correctAnswers[key]) {
        allCorrect = false;
        
      }
    }

    if (allCorrect) {
      alert('¡Todos los valores son correctos!');
      const successAudio = new Audio('/audio/success.mp3'); // Ruta relativa al archivo en public/audio
      successAudio.play();
       if (utteranceRef.current) {
      window.speechSynthesis.cancel(); // Detener el audio si se está reproduciendo
    }
      onNext();
    } else {
     const failureAudio = new Audio('/audio/error.mp3'); // Ruta relativa al archivo en public/audio
      failureAudio.play();
      alert('Algunos valores son incorrectos. Inténtalo de nuevo.');
    }
  };

  
 

 

  return (
    <div className="escena14">
    
    <div className="dialog-box-left">
        <img src={guacamayoImage} alt="Guacamayo" className="guacamayoBG-dialog-left" />
        <p>Descubre el mensaje secreto, al desarrollar bien las multiplicaciones. El resultado corresponde a una letra con la cual en el orden que están escritas las operaciones, forma una frase, debes determinar cuál es.</p>
      </div>
    
    <div className="operations-container">
     <div className ='row'> 
     <div className ='grid-container14 columns-4'>
        <div className="grid-item">15x20</div>
         <div className="grid-item">13x12</div> 
         <div className="grid-item">26x10</div>
         <div className="grid-item">20x13</div>
         {/*Siguiente Fila*/}
         <div className="grid-item-input14">  <input type="text" onChange={handleChange} name="input1" placeholder="?" value={inputValues.input1} className="input-operation14" /></div>
          <div className="grid-item-input14"> <input type="text" onChange={handleChange} name="input2" placeholder="?"  value={inputValues.input2} className="input-operation14"/></div> 
          <div className="grid-item-input14"> <input type="text" onChange={handleChange} name="input3" placeholder="?" value={inputValues.input3} className="input-operation14" /></div>
          <div className="grid-item-input14"> <input type="text" onChange={handleChange} name="input4" placeholder="?"  value={inputValues.input4} className="input-operation14" /></div>
     
      </div>
      <div className ='grid-container14 columns-2'>
        <div className="grid-item">65x4</div>
         <div className="grid-item">66x8</div> 
       
         {/*Siguiente Fila*/}
         <div className="grid-item-input14"> <input type="text" onChange={handleChange} name="input5" placeholder="?"  value={inputValues.input5} className="input-operation14" /></div>
          <div className="grid-item-input14"> <input type="text" onChange={handleChange} name="input6" placeholder="?" value={inputValues.input6} className="input-operation14" /></div> 
          
      </div>
      
      <div className ='grid-container14 columns-2'>
        <div className="grid-item">20x16</div>
         <div className="grid-item">23x20</div> 
       
         {/*Siguiente Fila*/}
         <div className="grid-item-input14"> <input  type="text" onChange={handleChange} name="input7" placeholder="?" value={inputValues.input7} className="input-operation14" /></div>
          <div className="grid-item-input14"> <input  type="text"onChange={handleChange} name="input8" placeholder="?" value={inputValues.input8} className="input-operation14" /></div> 
          
      </div>
      
     </div>
     
     <div className ='row'> 
       <div className ='grid-container14 columns-5'>
        <div className="grid-item">18x6</div>
         <div className="grid-item">92x5</div> 
         <div className="grid-item">15x12</div>
         <div className="grid-item">12x9</div>
          <div className="grid-item">20x6</div>
         {/*Siguiente Fila*/}
         <div className="grid-item-input14"> <input type="text" onChange={handleChange} name="input9" placeholder="?" value={inputValues.input9} className="input-operation14" /></div>
          <div className="grid-item-input14"> <input type="text" onChange={handleChange} name="input10" placeholder="?" value={inputValues.input10} className="input-operation14" /></div> 
          <div className="grid-item-input14"> <input  type="text" onChange={handleChange} name="input11" placeholder="?" value={inputValues.input11} className="input-operation14" /></div>
          <div className="grid-item-input14"> <input  type="text" onChange={handleChange} name="input12" placeholder="?" value={inputValues.input12} className="input-operation14" /></div>
          <div className="grid-item-input14"> <input  type="text" onChange={handleChange} name="input13" placeholder="?" value={inputValues.input13} className="input-operation14" /></div>
     
        </div>
         <div className ='grid-container14 columns-1'>
        <div className="grid-item">19x15</div>
         {/*Siguiente Fila*/}
         <div className="grid-item-input14"> <input  type="text" onChange={handleChange} name="input14" placeholder="?"  value={inputValues.input14} className="input-operation14" /></div>
        </div>
        
        <div className ='grid-container14 columns-2'>
        <div className="grid-item">20x13</div>
        <div className="grid-item">12x44</div> 
         {/*Siguiente Fila*/}
         <div className="grid-item-input14"> <input  type="text" onChange={handleChange} name="input15" placeholder="?" value={inputValues.input15} className="input-operation14" /></div>
         <div className="grid-item-input14"> <input  type="text" onChange={handleChange} name="input16" placeholder="?" value={inputValues.input16} className="input-operation14" /></div>
        </div>
        
        
      </div>
      
      <div className='row'>
        
        <div className ='grid-container14 columns-2'>
        <div className="grid-item">46x8</div>
        <div className="grid-item">12x10</div> 
         {/*Siguiente Fila*/}
         <div className="grid-item-input14"> <input  type="text" onChange={handleChange} name="input17" placeholder="?" value={inputValues.input17} className="input-operation14" /></div>
         <div className="grid-item-input14"> <input  type="text" onChange={handleChange} name="input18" placeholder="?" value={inputValues.input18} className="input-operation14" /></div>
        </div>
        
        <div className ='grid-container14 columns-3'>
        <div className="grid-item">30x15</div>
        <div className="grid-item">15x26</div> 
        <div className="grid-item">13x20</div> 
         {/*Siguiente Fila*/}
         <div className="grid-item-input14"> <input  type="text" onChange={handleChange} name="input19" placeholder="?" value={inputValues.input19} className="input-operation14" /></div>
         <div className="grid-item-input14"> <input  type="text" onChange={handleChange} name="input20" placeholder="?" value={inputValues.input20} className="input-operation14" /></div>
         <div className="grid-item-input14"> <input  type="text" onChange={handleChange} name="input21" placeholder="?" value={inputValues.input21} className="input-operation14" /></div>
        </div>
        
        <div className ='grid-container14 columns-4'>
        <div className="grid-item">130x2</div>
         <div className="grid-item">26x6</div> 
         <div className="grid-item">10x26</div>
         <div className="grid-item">12x15</div>
         {/*Siguiente Fila*/}
         <div className="grid-item-input14"> <input  type="text" onChange={handleChange} name="input22" placeholder="?" value={inputValues.input22} className="input-operation14" /></div>
          <div className="grid-item-input14"> <input  type="text" onChange={handleChange} name="input23" placeholder="?" value={inputValues.input23} className="input-operation14" /></div> 
          <div className="grid-item-input14"> <input  type="text" onChange={handleChange} name="input24" placeholder="?" value={inputValues.input24} className="input-operation14" /></div>
          <div className="grid-item-input14"> <input  type="text" onChange={handleChange} name="input25" placeholder="?"  value={inputValues.input25} className="input-operation14" /></div>
     
      </div>
        
      </div>
     
     <div className="multiplicaciones">
        {/* Renderiza las multiplicaciones */}
        {Object.keys(multiplicaciones).map((key) => (
          <div key={key} className="operacion">
            {key} = {multiplicaciones[key]}
          </div>
        ))}
      </div>
     
    </div>
    <button className="check-button" onClick={handleSubmit}>
        Verificar respuestas
      </button>
    
    </div>
  );
};

export default EscenaMultiplicacion;
