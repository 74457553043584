import React from 'react';
import './css/escena7.css'; // Importar los estilos
import guacamayoImage from '../guacamayoBG.png'; // Asegúrate de tener la imagen del guacamayo
import mapaBogota from '../mapaBogota.webp'
function Escena18({ avatar,onNext}) {




  const handleNext = () => {
    // Función para ir a la siguiente escena
    onNext();
  };

  return (
    <div className="escena18">
      {/* Fondo del mapa de Colombia */}

      {/* Niño o niña caminando hacia Medellín */}
      <img src={`/image/${avatar}.png`} alt="Niño caminando" className="nino-caminando18" />

      {/* Imagen del guacamayo */}
      <div className="guacamayo-container-movimiento-18">
        <img src={guacamayoImage} alt="Guacamayo" className="guacamayo7" />
        <p>Vámonos a Bogotá</p>
      </div>

      {/* Botón en el punto de Medellín */}
      <button className="boton-medellin" onClick={handleNext}>
        Ir a Bogotá
      </button>
    </div>
  );
}

export default Escena18;
