// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nino-caminando18 {
  position: absolute;
  bottom: 20%;
  left: 10%;
  width: 270px;
  height: 280px;
  animation: caminar7 4s linear forwards;
  z-index: 2;
} 
.guacamayo-container-movimiento-18 {
  position: absolute;
  top: 2%;
  left: 5%;
  z-index: 3;
  text-align: center;
  
}

.guacamayo-container-movimiento-18 p {
  color:white;
  font-weight:bold;
  font-size:xxx-large;
  -webkit-text-stroke : 1px black;
  margin-top: 10px; /* Agrega espacio encima del texto */
}
`, "",{"version":3,"sources":["webpack://./src/components/css/escena18.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,YAAY;EACZ,aAAa;EACb,sCAAsC;EACtC,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,UAAU;EACV,kBAAkB;;AAEpB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,+BAA+B;EAC/B,gBAAgB,EAAE,oCAAoC;AACxD","sourcesContent":[".nino-caminando18 {\r\n  position: absolute;\r\n  bottom: 20%;\r\n  left: 10%;\r\n  width: 270px;\r\n  height: 280px;\r\n  animation: caminar7 4s linear forwards;\r\n  z-index: 2;\r\n} \r\n.guacamayo-container-movimiento-18 {\r\n  position: absolute;\r\n  top: 2%;\r\n  left: 5%;\r\n  z-index: 3;\r\n  text-align: center;\r\n  \r\n}\r\n\r\n.guacamayo-container-movimiento-18 p {\r\n  color:white;\r\n  font-weight:bold;\r\n  font-size:xxx-large;\r\n  -webkit-text-stroke : 1px black;\r\n  margin-top: 10px; /* Agrega espacio encima del texto */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
