import React, { useState, useEffect,useRef } from 'react';
import './css/escena8.css'; // Asegúrate de tener un archivo CSS para esta escena
import guacamayoImage from '../guacamayoBG.png'; // Imagen del guacamayo
import whistleSound from '../pito.mp3'; // Sonido del pito

function Escena8({avatar, onNext }) {
  const hasSpokenRef = useRef(false); // Controlar si ya ha hablado
  const utteranceRef = useRef(null); // Referencia al SpeechSynthesisUtterance para detener el audio
  const [soundPlaying, setSoundPlaying] = useState(false);
  const [childPosition, setChildPosition] = useState('start'); // Controla el movimiento de los niños
  const [reachedCenter, setReachedCenter] = useState(false); // Controla si han llegado al centro de la cancha

  useEffect(() => {
  
  


    // Sonido de inicio de la escena
    const speak = (text) => {
      if (hasSpokenRef.current) return;
      const utterance = new SpeechSynthesisUtterance(text);
      utteranceRef.current = utterance;
      // Obtener todas las voces disponibles
      const voices = window.speechSynthesis.getVoices();

      // Buscar una voz en español (Latinoamérica)
      const vozLatina = voices.find(voice => voice.lang === 'es-MX' || voice.lang === 'es-US' || voice.lang === 'es-ES');

      // Si se encuentra una voz en español, asignarla
      if (vozLatina) {
        utterance.voice = vozLatina;
      }

      // Reproducir el mensaje
      window.speechSynthesis.speak(utterance);
      hasSpokenRef.current = true;
      // Al terminar de hablar, mover el avatar hacia el centro
      
    };speak('En este estadio, vas a encontrar las actividades que corresponden a la RESTA, pon a prueba tus conocimientos, dirígete al centro de la cancha vamos a comenzar.');
  }, []);

  const handleWhistleAndMove = () => {
    // Reproducir sonido de pito
    const whistle = new Audio(whistleSound);
    whistle.play();

    // Mover a los niños al centro de la cancha después de que lleguen al guacamayo
    setTimeout(() => {
      setChildPosition('center');
      setReachedCenter(true);
    }, 2000); // Simula el tiempo de caminar hasta el centro
  };

  const handleNext = () => {
    // Ir a la siguiente escena
    if (soundPlaying) {
      setSoundPlaying(false);
    }
    if (utteranceRef.current) {
      window.speechSynthesis.cancel(); // Detener el audio si se está reproduciendo
    }  
    onNext();
  };

  return (
    <div className="escena8">
      
      <div className="dialog-box">
        <img src={guacamayoImage} alt="Guacamayo" className="guacamayoBG" />
        <p>En este estadio, vas a encontrar las actividades que corresponden a la RESTA, pon a prueba tus conocimientos, dirígete al centro de la cancha, vamos a comenzar.</p>
      </div>
      {/* Niño y niña caminando hacia el guacamayo y luego al centro de la cancha */}
      <img src={`/image/${avatar}.png`} alt="Niño" className={`avatar ${childPosition}`} />
      

     

      {/* Botón en el centro de la cancha para ir a la siguiente escena */}
      {reachedCenter && (
        <button className="boton-balon" onClick={handleNext}>
          <img src="/image/balon.webp" alt="Balón de fútbol" />
        </button>
      )}

      {/* Botón para simular el movimiento hacia el centro */}
      {!reachedCenter && (
        <button className="start-button" onClick={handleWhistleAndMove}>
          Empezar
        </button>
      )}
    </div>
  );
}

export default Escena8;
