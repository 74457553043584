import React, { useState, useRef, useEffect } from 'react';
import './css/Escena10.css';
import guacamayoImage from '../guacamayoBG.png';

const questions = [
  { id: 1, text: "¿En la ciudad de Bogotá viven 8.034.649 personas, en la ciudad de Cali viven 2.283.846 habitantes, ¿Cuántos habitantes menos hay en Cali que en Bogotá? ", correctAnswer: "5.750.803" },
  { id: 2, text: "La distancia entre la ciudad de Medellín y la ciudad de Bogotá es de 417.600 metros, la distancia entre Medellín y Santa Marta es de 836.452 metros, ¿cuál es la diferencia entre las dos distancias?", correctAnswer: "418.852" },
  { id: 3, text: "La distancia entre la Tierra y Venus es de sesenta y un millón de kilómetros; si la diferencia entre esa distancia y la distancia de la Tierra a marte es de 6.400.000 km, ¿Cuál será la distancia de la Tierra a Marte?", correctAnswer: "54.600.000 km" },
  { id: 4, text: "El camión de Pedro, carga 138.753 kilos de alimentos y el camión de José carga 764.000 kilos de alimentos, ¿Cuántos kilos menos carga el camión de Pedro? ", correctAnswer: "625.247" },
  { id: 5, text: "Roberto quiere comprar un terreno para construir su casa, este tiene un valor de $21892000, él dispone de $6594800 y un banco le presta $12650000, ¿Cuánto le hace falta para completar lo del terreno?", correctAnswer: "2.647.200" }
];

const answers = [
  { id: 1, text: "54.600.000 km"},
  { id: 2, text: "2.647.200" },
  { id: 3, text: "625.247"},
  { id: 4, text: "5.750.803"},
  { id: 5, text: "418.852" }
];

function Escena10({ avatar, onNext }) {
  const hasSpokenRef = useRef(false);
  const utteranceRef = useRef(null);

  const [connections, setConnections] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPoint, setStartPoint] = useState(null);
  const questionRefs = useRef([]);
  const answerRefs = useRef([]);

  useEffect(() => {
    const speak = (text) => {
      if (hasSpokenRef.current) return;
      const utterance = new SpeechSynthesisUtterance(text);
      utteranceRef.current = utterance;
      const voices = window.speechSynthesis.getVoices();
      const vozLatina = voices.find(voice => voice.lang === 'es-MX' || voice.lang === 'es-US' || voice.lang === 'es-ES');
      if (vozLatina) utterance.voice = vozLatina;
      window.speechSynthesis.speak(utterance);
      hasSpokenRef.current = true;
    };
    speak('Ahora analiza la situación planteada y relaciona con la respuesta adecuada.');
  }, []);

  // Añade un listener al evento de redimensionamiento de la ventana
  useEffect(() => {
    const handleResize = () => {
      setConnections([...connections]); // Dispara una actualización para recalcular las líneas
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [connections]);

  const handleMouseDown = (id, type) => {
    setStartPoint({ id, type });
    setIsDrawing(true);
  };

  const handleMouseUp = (id, type) => {
    if (isDrawing && startPoint) {
      setIsDrawing(false);
      if (startPoint.type !== type) {
        const newConnection = { from: startPoint, to: { id, type } };
        setConnections([...connections, newConnection]);
      }
    }
  };

  const validateConnections = () => {
    let correctConnections = 0;
    connections.forEach((connection) => {
      const question = questions.find(q => q.id === connection.from.id);
      const answer = answers.find(a => a.id === connection.to.id);
      if (question && answer && question.correctAnswer === answer.text) {
        correctConnections++;
      }
    });
    
    if (correctConnections === 5) {
      const successAudio = new Audio('/audio/success.mp3');
      successAudio.play();
      onNext();
    } else {
      setConnections([]);
      const failureAudio = new Audio('/audio/error.mp3');
      failureAudio.play();
      alert(`Tienes ${correctConnections} respuestas correctas de ${questions.length}`);
    }
  };

  return (
    <div className="Escena10">
      <div className="dialog-box">
        <img src={guacamayoImage} alt="Guacamayo" className="guacamayoBG" />
        <p>Ahora analiza la situación planteada y relaciona con la respuesta adecuada</p>
      </div>
      <div className="tituloCentrado"><h1>Unir Preguntas y Respuestas</h1></div>
      <div className="container">
        <div className="questions">
          <h3 className="esc">Preguntas</h3>
          {questions.map((q, index) => (
            <div
              key={q.id}
              className="question-box"
              ref={el => questionRefs.current[index] = el}
              onMouseDown={() => handleMouseDown(q.id, 'question')}
              onMouseUp={() => handleMouseUp(q.id, 'question')}
            >
              {q.text}
            </div>
          ))}
        </div>
        <div className="answers">
          <h3 className="esc">Respuestas</h3>
          {answers.map((a, index) => (
            <div
              key={a.id}
              className="answer-box"
              ref={el => answerRefs.current[index] = el}
              onMouseDown={() => handleMouseDown(a.id, 'answer')}
              onMouseUp={() => handleMouseUp(a.id, 'answer')}
            >
              {a.text}
            </div>
          ))}
        </div>
      </div>

      <svg className="svg-lines">
        {connections.map((connection, index) => {
          const questionElem = questionRefs.current[connection.from.id - 1];
          const answerElem = answerRefs.current[connection.to.id - 1];
          if (questionElem && answerElem) {
            const questionRect = questionElem.getBoundingClientRect();
            const answerRect = answerElem.getBoundingClientRect();
            return (
              <line
                key={index}
                x1={questionRect.right}
                y1={questionRect.top + questionRect.height / 2}
                x2={answerRect.left}
                y2={answerRect.top + answerRect.height / 2}
                stroke="black"
                strokeWidth="2"
              />
            );
          }
          return null;
        })}
      </svg>

      <div className="posicionBaja">
        <button className="botonConfirmar" onClick={validateConnections}>Validar</button>
      </div>
    </div>
  );
}

export default Escena10;
