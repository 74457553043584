import React, { useState, useEffect,useRef } from 'react';
import './css/escena15.css';  // Asegúrate de que los estilos estén correctamente vinculados
import guacamayoBG from '../guacamayoBG.png';
function Escena15({ avatar,onNext  }) {
      const [selectedNumbers, setSelectedNumbers] = useState([[], [], []]); // Tres bloques con dos espacios cada uno
      const [validatedBlocks, setValidatedBlocks] = useState([false, false, false]); // Rastrear bloques yvalidados
      const hasSpokenRef = useRef(false); // Controlar si ya ha hablado
      const utteranceRef = useRef(null); // Referencia al SpeechSynthesisUtterance para detener el audio
      const [avatarPosition] = useState('start'); // Para animar el avatar
      const correctProduct = 210; // El valor correcto que debe tener cada bloque

    useEffect(() => {
  
  
        // Mover el avatar al centro después de unos segundos
        setTimeout(() => {
        /*setAvatarPosition('center');*/
        }, 2000);

        // Sonido de inicio de la escena
        const audio = new Audio('/audio/pito.mp3');
        audio.play();
        const speak = (text) => {
        if (hasSpokenRef.current) return;
        const utterance = new SpeechSynthesisUtterance(text);
        utteranceRef.current = utterance;
        // Obtener todas las voces disponibles
        const voices = window.speechSynthesis.getVoices();

        // Buscar una voz en español (Latinoamérica)
        const vozLatina = voices.find(voice => voice.lang === 'es-MX' || voice.lang === 'es-US' || voice.lang === 'es-ES');

        // Si se encuentra una voz en español, asignarla
        if (vozLatina) {
            utterance.voice = vozLatina;
        }

        // Reproducir el mensaje
        window.speechSynthesis.speak(utterance);
        hasSpokenRef.current = true;
        // Al terminar de hablar, mover el avatar hacia el centro
      
    };speak('La siguiente actividad consiste en formar parejas de cantidades sin repetir, de tal forma que el resultado de multiplicarlas sea el mismo.');
  }, []);

  const handleDragStart = (e, number) => {
    e.dataTransfer.setData('number', number); // Guardar el número arrastrado
  };

  const handleDrop = (e, blockIndex, boxIndex) => {
    const number = parseFloat(e.dataTransfer.getData('number')); // Obtener el número arrastrado
    const newSelectedNumbers = [...selectedNumbers];
    const emptyIdx = newSelectedNumbers[blockIndex].findIndex((val) => val === undefined);
    // Solo permitir un número por cuadro
    if (newSelectedNumbers[blockIndex].length < 2 || emptyIdx!==-1) {
      newSelectedNumbers[blockIndex][boxIndex] = number;
      setSelectedNumbers(newSelectedNumbers);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Permitir que el número sea soltado
  };
  
 useEffect(() => {
    // Recorre todos los bloques para verificar si hay dos números en alguno
    selectedNumbers.forEach((block,blockIndex) => {
      if (block.length === 2 && !validatedBlocks[blockIndex] && block[0]!==undefined) {
        const product = block.reduce((acc, curr) => acc * curr, 1); // Sumar los valores del bloque
        console.log(product);
        if (product === correctProduct) {
          // Reproducir sonido de éxito
          const successAudio = new Audio('/audio/correct.mp3');
          successAudio.play();
          const newValidatedBlocks = [...validatedBlocks];
          newValidatedBlocks[blockIndex] = true;
          setValidatedBlocks(newValidatedBlocks);
        } else {
          // Reproducir sonido de error
          const errorAudio = new Audio('/audio/error.mp3');
          errorAudio.play();
        }
      }
    }); }, [selectedNumbers, validatedBlocks]);

  const validateSums = () => {
    let valid = true;

    for (let i = 0; i < selectedNumbers.length; i++) {
      const product= selectedNumbers[i].reduce((acc, curr) => acc * curr, 1); // Sumar los valores de cada bloque
      if (product!== correctProduct) {
        valid = false;
        break;
      }
    }

    if (valid) {
      alert('¡Prueba superada! Las multiplicaciones son correctas.');
       if (utteranceRef.current) {
      window.speechSynthesis.cancel(); // Detener el audio si se está reproduciendo
    }
    const successAudio = new Audio('/audio/success.mp3'); // Ruta relativa al archivo en public/audio
      successAudio.play();
      onNext(); // Llamar a la función para completar la escena
    } else {
      alert('Una o más multiplicaciones son incorrectas. Por favor, inténtalo de nuevo.');
      const failureAudio = new Audio('/audio/error.mp3');
      failureAudio.play();
      setValidatedBlocks([false,false,false]);
      setSelectedNumbers([[], [], []]); // Vaciar los cuadros para volver a intentar
    }
  };

  return (
    <div className="escena15">
      {/* Agregar el guacamayo volando */}
      <div className="dialog-box-left">
        <img src={guacamayoBG} alt="Guacamayo" className="guacamayoBG-dialog-left" />
        <p>La siguiente actividad consiste en formar parejas de cantidades sin repetir, de tal forma que el resultado de multiplicarlas sea el mismo.</p>
      </div>

      {/* Mostrar el avatar */}
      <img
        src={`/image/${avatar}.png`}
        alt="Avatar del Jugador"
        className={`avatar ${avatarPosition}`}
      />

      <div className="numeros-arrastrables">
        <div className="numero15" draggable onDragStart={(e) => handleDragStart(e, 6)}>6</div>
        <div className="numero15" draggable onDragStart={(e) => handleDragStart(e, 10)}>10</div>
        <div className="numero15" draggable onDragStart={(e) => handleDragStart(e, 14)}>14</div>
        <div className="numero15" draggable onDragStart={(e) => handleDragStart(e, 21)}>21</div>
        <div className="numero15" draggable onDragStart={(e) => handleDragStart(e, 35)}>35</div>
        <div className="numero15" draggable onDragStart={(e) => handleDragStart(e, 15)}>15</div>
      </div>

      <div className="bloques-suma">
        {selectedNumbers.map((block, blockIndex) => (
          <div key={blockIndex} className="bloque">
            <div className="cuadro" onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, blockIndex, 0)}>
              {block[0]}
            </div>
            <div className="simbolo">x</div>
            <div className="cuadro" onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, blockIndex, 1)}>
              {block[1]}
            </div>
          </div>
        ))}
      </div>

      <button onClick={validateSums} className="boton-validar">
        Validar parejas
      </button>
    </div>
  );
}

export default Escena15;


