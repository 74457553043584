  import React, { useState, useEffect, useRef } from 'react';
  import './css/intro.css'; // Asegúrate de que App.css esté bien vinculado
  
  function Intro({ onNext }) {
    const [selectedAvatar, setSelectedAvatar] = useState(null); // Guardar el avatar seleccionado
    const hasSpokenRef = useRef(false); // Controla si ya se ha hablado
    const utteranceRef = useRef(null); // Referencia al SpeechSynthesisUtterance para detener el audio

    useEffect(() => {
      // Función para reproducir el mensaje de bienvenida
      const speak = (text) => {
          if (hasSpokenRef.current) return; // Si ya se ha hablado, no hacer nada
  
          const utterance = new SpeechSynthesisUtterance(text);
          utteranceRef.current = utterance;
          const voices = window.speechSynthesis.getVoices();
          const spanishVoice = voices.find((voice) => voice.lang.startsWith('es')); // Encontrar voz en español
  
          if (spanishVoice) {
            utterance.voice = spanishVoice;
          }
  
          utterance.lang = 'es-ES'; // Cambiar idioma a español
          window.speechSynthesis.speak(utterance);
  
          // Marcar como "ha hablado" cuando termine
          
          hasSpokenRef.current = true;
          
  
        };speak('Bienvenidos al campeonato matematico del grado cuarto, vas a pasar por los estadios de Barranquilla, Medellin, Cali y Bogota; encontraras unas actividades que debes superar para pasar de un estadio a otro y asi ganar la copa. Elige un jugador e ingresa a participar. ¡Mucha suerte!');
  
      // Ejecutar la función de hablar al cargar la escena
     
    }, []); // Eliminar la dependencia para ejecutar una vez
    
   
  
    const handleAvatarSelection = (avatar) => {
      setSelectedAvatar(avatar); // Guardar el avatar seleccionado
    };
  
    const handleStart = () => {
    if (utteranceRef.current) {
      window.speechSynthesis.cancel(); // Detener el audio si se está reproduciendo
    }
     onNext(selectedAvatar);

       // Pasar a la siguiente escena
    };
  
    return (
      <div className="centered_intro">
        <h1 className='introH1'>Campeonato Matemático</h1>
        <h2>Elige tu Jugador:</h2>
  
        {/* Mostrar avatares si no se ha seleccionado uno */}
        {!selectedAvatar && (
          <div id="avatar-container">
            <button className="avatar-button" onClick={() => handleAvatarSelection('nino-azul-rojo')}>
              <img src="/image/nino-azul-rojo.png" alt="Niño Azul y Rojo" />
             <div className='nombresPersonajes' ><p>  Personaje 1 </p> </div>
            </button>
            <button className="avatar-button" onClick={() => handleAvatarSelection('nino-amarillo-verde')}>
              <img src="/image/nino-amarillo-verde.png" alt="Niño Amarillo y Verde" />
               <div className='nombresPersonajes' ><p>  Personaje 2 </p> </div>
            </button>
            <button className="avatar-button" onClick={() => handleAvatarSelection('nina-rojo-blanco')}>
              <img src="/image/nina-rojo-blanco.png" alt="Niña Rojo y Blanco" />
              <div className='nombresPersonajes' ><p>  Personaje 3 </p> </div>
            </button>
            <button className="avatar-button" onClick={() => handleAvatarSelection('nina-amarillo-verde')}>
              <img src="/image/nina-amarillo-verde.png" alt="Niña Amarillo y Verde" />
               <div className='nombresPersonajes' ><p>  Personaje 4 </p> </div>
            </button>
          </div>
        )}
  
        {/* Mostrar avatar seleccionado y botón comenzar */}
        {selectedAvatar && (
          <div className="avatar-selected-container">
            <div className="selected-avatar">
              <img src={`/image/${selectedAvatar}.png`} alt="Avatar Seleccionado" className="avatar-selected" />
            </div>
            <button onClick={handleStart} className="start-button">
              Comenzar
            </button>
          </div>
        )}
      </div>
    );
  }
  
  export default Intro;
  
  
  
  
