import React, {  } from 'react';
import './css/escena18.css'; // Importar los estilos
import guacamayoImage from '../guacamayoBG.png'; // Asegúrate de tener la imagen del guacamayo
 // Asegúrate de tener el audio de fondo


function Escena7({ avatar,onNext}) {
  



  const handleNext = () => {
    
   
    onNext();
  };

  return (
    <div className="escena7">

      {/* Niño o niña caminando hacia Medellín */}
      <img src={`/image/${avatar}.png`} alt="Niño caminando" className="nino-caminando7" />

      {/* Imagen del guacamayo */}
      <div className="guacamayo-container-movimiento">
        <img src={guacamayoImage} alt="Guacamayo" className="guacamayo7" />
        <p>Vámonos a Medellín</p>
      </div>

      {/* Botón en el punto de Medellín */}
      <button className="boton-medellin" onClick={handleNext}>
        Ir a Medellín
      </button>
    </div>
  );
}

export default Escena7;
