import React, { useState,useRef,useEffect } from 'react';
import './css/escena16.css';
import guacamayo from '../guacamayoBG.png'; // Imagen del guacamayo
import cancha from '../estadioCali3.webp'; // Imagen de la cancha
import payasoImage from '../payaso.png';
import casaPayasoImage from '../casaPayaso.png';
const EscenaPayaso = ({ avatar, onNext }) => {
  const [selectedSquares, setSelectedSquares] = useState([]);
  const hasSpokenRef = useRef(false); // Controlar si ya ha hablado
  const utteranceRef = useRef(null);
  const correctNumbers = [5, 25, 100, 50, 10, 15, 20, 55, 60, 95, 70]; // Números correctos
  const numbersOnBoard = [
    22, 106, 99, 5,
    35, 8, 25, 100,
    45, 20, 17, 50,
    60, 36, 15, 9,
    70, 95, 59, 8,
    227, 6, 12
  ]; // Números del tablero
  
  
   useEffect(() => {
  
  


    // Sonido de inicio de la escena
    const speak = (text) => {
      if (hasSpokenRef.current) return;
      const utterance = new SpeechSynthesisUtterance(text);
      utteranceRef.current = utterance;
      // Obtener todas las voces disponibles
      const voices = window.speechSynthesis.getVoices();

      // Buscar una voz en español (Latinoamérica)
      const vozLatina = voices.find(voice => voice.lang === 'es-MX' || voice.lang === 'es-US' || voice.lang === 'es-ES');

      // Si se encuentra una voz en español, asignarla
      if (vozLatina) {
        utterance.voice = vozLatina;
      }

      // Reproducir el mensaje
      window.speechSynthesis.speak(utterance);
      hasSpokenRef.current = true;
      // Al terminar de hablar, mover el avatar hacia el centro
      
    };speak('Ayuda al payaso a llegar a su casa, sigue el camino seleccionando los cuadros que contengan los números que resulten al multiplicar 5 por otro número.');
  }, []);
  
  const handleSquareClick = (number) => {
    setSelectedSquares((prev) => {
      if (prev.includes(number)) {
        return prev.filter((n) => n !== number);
      } else {
        if (number % 5 === 0) {
        // Reproduce el audio para múltiplos de 5
        const audioCorrect = new Audio('/audio/correct.mp3');
        audioCorrect.play();
    } else {
        // Reproduce el audio para números no múltiplos de 5
        const audioIncorrect = new Audio('/audio/error.mp3');
        audioIncorrect.play();
    }
        return [...prev, number];
      }
    });
  };

  const checkAnswers = () => {
    const isCorrect = correctNumbers.every((num) =>
      selectedSquares.includes(num)
    );

    if (isCorrect) {
      alert('¡Has ayudado al payaso a llegar a su casa!');
      const audioSucess = new Audio('/audio/success.mp3');
      audioSucess.play();
      onNext(); // Avanza a la siguiente escena
    } else {
      alert('Algunos números seleccionados son incorrectos. Inténtalo de nuevo.');
    }
  };

  return (
    <div className="escena-payaso">
     
      <div className="a">
        <img src={`/image/${avatar}.png`} alt="Niño" className="nino-nina" />
      </div>
      
      <div className="dialog-box">
        <img src={guacamayo} alt="Guacamayo" className="guacamayoBG" />
        <p>Ayuda al payaso a llegar a su casa, sigue el camino seleccionando los cuadros que contengan los números que resulten al multiplicar 5 por otro número.</p>
      </div>
    
<div className='rombos'>
        <img className='payaso' src={payasoImage} />
        <img className='casaPayaso' src={casaPayasoImage} />
        <div className='rotar'>
          <div className='prueba'>
         
            <div className={`prueba2 ${selectedSquares.includes(22) ? 'selected' : ''}`} onClick={() => handleSquareClick(22)}>
              <div className='texto-rombo'>22</div>
            </div>
          </div>

          <div className='prueba'>
            <div className={`prueba2 ${selectedSquares.includes(45) ? 'selected' : ''}`} onClick={() => handleSquareClick(45)}>
              <div className='texto-rombo'>45</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(35) ? 'selected' : ''}`} onClick={() => handleSquareClick(35)}>
              <div className='texto-rombo'>35</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(106) ? 'selected' : ''}`} onClick={() => handleSquareClick(106)}>
              <div className='texto-rombo'>106</div>
            </div>
          </div>

          <div className='prueba'>
            <div className={`prueba2 ${selectedSquares.includes(60) ? 'selected' : ''}`} onClick={() => handleSquareClick(60)}>
              <div className='texto-rombo'>60</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(55) ? 'selected' : ''}`} onClick={() => handleSquareClick(55)}>
              <div className='texto-rombo'>55</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(20) ? 'selected' : ''}`} onClick={() => handleSquareClick(20)}>
              <div className='texto-rombo'>20</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(7) ? 'selected' : ''}`} onClick={() => handleSquareClick(7)}>
              <div className='texto-rombo'>7</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(99) ? 'selected' : ''}`} onClick={() => handleSquareClick(99)}>
              <div className='texto-rombo'>99</div>
            </div>
          </div>

          <div className='prueba'>
            <div className={`prueba2 ${selectedSquares.includes(70) ? 'selected' : ''}`} onClick={() => handleSquareClick(70)}>
              <div className='texto-rombo'>70</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(95) ? 'selected' : ''}`} onClick={() => handleSquareClick(95)}>
              <div className='texto-rombo'>95</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(36) ? 'selected' : ''}`} onClick={() => handleSquareClick(36)}>
              <div className='texto-rombo'>36</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(15) ? 'selected' : ''}`} onClick={() => handleSquareClick(15)}>
              <div className='texto-rombo'>15</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(17) ? 'selected' : ''}`} onClick={() => handleSquareClick(17)}>
              <div className='texto-rombo'>17</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(25) ? 'selected' : ''}`} onClick={() => handleSquareClick(25)}>
              <div className='texto-rombo'>25</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(5) ? 'selected' : ''}`} onClick={() => handleSquareClick(5)}>
              <div className='texto-rombo'>5</div>
              
            </div>
          </div>

          <div className='prueba'>
            <div className={`prueba2 ${selectedSquares.includes(227) ? 'selected' : ''}`} onClick={() => handleSquareClick(227)}>
              <div className='texto-rombo'>227</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(59) ? 'selected' : ''}`} onClick={() => handleSquareClick(59)}>
              <div className='texto-rombo'>59</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(10) ? 'selected' : ''}`} onClick={() => handleSquareClick(10)}>
              <div className='texto-rombo'>10</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(50) ? 'selected' : ''}`} onClick={() => handleSquareClick(50)}>
              <div className='texto-rombo'>50</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(100) ? 'selected' : ''}`} onClick={() => handleSquareClick(100)}>
              <div className='texto-rombo'>100</div>
            </div>
          </div>

          <div className='prueba'>
            <div className={`prueba2 ${selectedSquares.includes(6) ? 'selected' : ''}`} onClick={() => handleSquareClick(6)}>
              <div className='texto-rombo'>6</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(8) ? 'selected' : ''}`} onClick={() => handleSquareClick(8)}>
              <div className='texto-rombo'>8</div>
            </div>
            <div className={`prueba2 ${selectedSquares.includes(9) ? 'selected' : ''}`} onClick={() => handleSquareClick(9)}>
              <div className='texto-rombo'>9</div>
            </div>
          </div>

          <div className='prueba'>
            <div className={`prueba2 ${selectedSquares.includes(12) ? 'selected' : ''}`} onClick={() => handleSquareClick(12)}>
              <div className='texto-rombo'>12</div>
            </div>
          </div>
        </div>



</div>
 
      <button className="check-button" onClick={checkAnswers}>
        Verificar selección
      </button>
    </div>
  );
};

export default EscenaPayaso;
