import React, { useState,useRef,useEffect } from 'react';
import './css/Escena4.css';
import guacamayoBG from '../guacamayoBG.png';
function Escena4({avatar, onNext }) {
  const hasSpokenRef = useRef(false); // Controlar si ya ha hablado
const utteranceRef = useRef(null); // Referencia al SpeechSynthesisUtterance para detener el audio
  const [selectedNumbers, setSelectedNumbers] = useState([null, null, null, null, null, null, null, null, null]);
   const [avatarPosition] = useState('start'); // Para animar el avatar
  const numbers = [71, 72, 73, 74, 75, 76, 77, 78, 79]; // Números disponibles

    useEffect(() => {
  
  


    // Sonido de inicio de la escena
    const speak = (text) => {
      if (hasSpokenRef.current) return;
      const utterance = new SpeechSynthesisUtterance(text);
      utteranceRef.current = utterance;
      // Obtener todas las voces disponibles
      const voices = window.speechSynthesis.getVoices();

      // Buscar una voz en español (Latinoamérica)
      const vozLatina = voices.find(voice => voice.lang === 'es-MX' || voice.lang === 'es-US' || voice.lang === 'es-ES');

      // Si se encuentra una voz en español, asignarla
      if (vozLatina) {
        utterance.voice = vozLatina;
      }

      // Reproducir el mensaje
      window.speechSynthesis.speak(utterance);
      hasSpokenRef.current = true;
      // Al terminar de hablar, mover el avatar hacia el centro
      
    };speak('Muy bien, has superado el reto anterior. Ahora, en la siguiente actividad debes ubicar todas las fichas de afuera, que van del 71 al 79, en la figura central, de tal forma que al sumar los valores que ubiques en las líneas del mismo color negro, azul claro, verde y amarillo, den igual resultado.');
  }, []);
  
  
  const handleDragStart = (e, number) => {
    e.dataTransfer.setData('number', number); // Guardar el número arrastrado
  };

  const handleDrop = (e, index) => {
    const number = parseInt(e.dataTransfer.getData('number')); // Obtener el número arrastrado
    const newSelectedNumbers = [...selectedNumbers];
    newSelectedNumbers[index] = number; // Colocar el número en la casilla correspondiente
    setSelectedNumbers(newSelectedNumbers);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Permitir que el número sea soltado
  };

  const validateSums = () => {
    const diagonal1 = selectedNumbers[0] + selectedNumbers[4] + selectedNumbers[8];
    const diagonal2 = selectedNumbers[2] + selectedNumbers[4] + selectedNumbers[6];
    const horizontalMiddle = selectedNumbers[3] + selectedNumbers[4] + selectedNumbers[5];
    const verticalMiddle = selectedNumbers[1] + selectedNumbers[4] + selectedNumbers[7];

    const isValid = [diagonal1, diagonal2, horizontalMiddle, verticalMiddle].every(sum => sum === 228);

    if (isValid) {
      if (utteranceRef.current) {
      window.speechSynthesis.cancel(); // Detener el audio si se está reproduciendo
    }
      const successAudio = new Audio('/audio/success.mp3');
      successAudio.play();
      alert('¡Prueba superada!');
      onNext(); // Pasar a la siguiente escena
    } else {
      const failureAudio = new Audio('/audio/error.mp3');
      failureAudio.play();
      alert('Una o más sumas son incorrectas. Por favor, inténtalo de nuevo.');
      setSelectedNumbers([null, null, null, null, null, null, null, null, null]); // Resetear los números si es incorrecto
    }
  };

  return (
    <div className="escena4">
     <img
        src={`/image/${avatar}.png`}
        alt="Avatar del Jugador"
        className={`avatar ${avatarPosition}`}
      />
       <div className="dialog-box">
        <img src={guacamayoBG} alt="Guacamayo" className="guacamayoBG" />
        <p>Muy bien, has superado el reto anterior. Ahora, en la siguiente actividad debes ubicar todas las fichas de afuera, que van del 71 al 79, en la figura central, de tal forma que al sumar los valores que ubiques en las líneas del mismo color negro, azul claro, verde y amarillo, den igual resultado.</p>
      </div>
      <div className="numeros-arrastrables">
        {numbers.map((number, index) => (
          <div
            key={index}
            className="numero"
            draggable
            onDragStart={(e) => handleDragStart(e, number)}
          >
            {number}
          </div>
        ))}
      </div>

      <div className="casillas">
        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
          <div
            key={index}
            className={`casilla ${index === 4 ? 'center' : ''}`}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
          >
            {selectedNumbers[index]}
          {index === 3 && <div className="casilla-horizontal"></div>} {/* Línea horizontal izquierda */}
          {index === 4 && <div className="casilla-horizontal"></div>} {/* Línea horizontal derecha */}
          {index === 1 && <div className="casilla-vertical"></div>} {/* Línea vertical superior */}
          {index === 4 && <div className="casilla-vertical"></div>} {/* Línea vertical inferior */}
          {index === 2 && <div className="casilla-diagonal-left"></div>} {/* Diagonal izquierda */}
          {index === 0 && <div className="casilla-diagonal-right"></div>} {/* Diagonal derecha */}
          {index === 4 && <div className="casilla-diagonal-left"></div>} {/* Diagonal izquierda */}
          {index === 4 && <div className="casilla-diagonal-right"></div>} {/* Diagonal derecha */}
          </div>
        ))}
      </div>

      <button onClick={validateSums} className="boton-validar">
        Validar sumas
      </button>
    </div>
  );
}

export default Escena4;


