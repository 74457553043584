import React, { useState,useRef,useEffect } from 'react';
import './css/escena20.css'; // Aseg�rate de tener un archivo CSS vinculado
import guacamayoImage from '../guacamayoBG.png';


function Escena20({ avatar,onNext }) {
  const hasSpokenRef = useRef(false); // Controlar si ya ha hablado
  const utteranceRef = useRef(null);
  const [boxes, setBoxes] = useState([[], [], [], []]); // Cuatro cajas vac�as
  const [balloons, setBalloons] = useState(Array.from({ length: 24 }, (_, i) => i + 1)); // 24 balones
  const [divisionResult, setDivisionResult] = useState(''); // Campo para escribir la divisi�n
  const [dividendo, setDividendo] = useState(''); // Para el valor del dividendo
  const [divisor, setDivisor] = useState(''); 
  const [resultado, setResultado] = useState('');
  
   useEffect(() => {
  
  


    // Sonido de inicio de la escena
    const speak = (text) => {
      if (hasSpokenRef.current) return;
      const utterance = new SpeechSynthesisUtterance(text);
      utteranceRef.current = utterance;
      // Obtener todas las voces disponibles
      const voices = window.speechSynthesis.getVoices();

      // Buscar una voz en espa�ol (Latinoam�rica)
      const vozLatina = voices.find(voice => voice.lang === 'es-MX' || voice.lang === 'es-US' || voice.lang === 'es-ES');

      // Si se encuentra una voz en espa�ol, asignarla
      if (vozLatina) {
        utterance.voice = vozLatina;
      }

      // Reproducir el mensaje
      window.speechSynthesis.speak(utterance);
      hasSpokenRef.current = true;
      // Al terminar de hablar, mover el avatar hacia el centro
      
    };speak('Ahora debes separar los balones que se encuentran en la caja en partes iguales.');
  }, []);
  
  const handleDragStart = (e, balloon) => {
    e.dataTransfer.setData('balloon', balloon); // Guardar el bal�n arrastrado
  };

  const handleDrop = (e, boxIndex) => {
    const balloon = parseInt(e.dataTransfer.getData('balloon')); // Obtener el bal�n arrastrado
    const newBoxes = [...boxes];

    if (newBoxes[boxIndex].length < 6) {
      newBoxes[boxIndex] = [...newBoxes[boxIndex], balloon];
      setBoxes(newBoxes);
      setBalloons(balloons.filter(b => b !== balloon)); // Remover el bal�n de la caja inicial
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Permitir que el bal�n sea soltado
  };

  const handleSubmit = () => {
     const correctDivision = (dividendo === '24' && divisor === '4' && resultado === '6');

    if (correctDivision && boxes.every(box => box.length === 6)) {
      const successAudio = new Audio('/audio/success.mp3');
      successAudio.play();
      alert('�Prueba superada! Has separado correctamente los balones.');
      if (utteranceRef.current) {
      window.speechSynthesis.cancel(); // Detener el audio si se est� reproduciendo
    }
      onNext();
    } else {
      const failureAudio = new Audio('/audio/error.mp3');
      failureAudio.play();
      alert('La divisi�n o la distribuci�n de los balones es incorrecta. Int�ntalo de nuevo.');
      setDivisionResult('');
    }
  };

  return (
    <div className="escena-20">
      <div className="header">
        <img src={`/image/${avatar}.png`} alt="Avatar" className="avatar" />
        <img src={guacamayoImage} alt="Guacamayo" className="guacamayo20" />
        <h2>Ahora debes separar los balones que se encuentran en la caja en partes iguales.</h2>
      </div>

      <div className="balloons-container">
        {balloons.map(balloon => (
          <div
            key={balloon}
            className="balloon"
            draggable
            onDragStart={(e) => handleDragStart(e, balloon)}
          >
            {balloon}
          </div>
        ))}
      </div>

      <div className="boxes-container">
        {boxes.map((box, index) => (
          <div
            key={index}
            className="box"
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
          >
            {box.map(balloon => (
              <div key={balloon} className="box-balloon">{balloon}</div>
            ))}
          </div>
        ))}
      </div>

        {/* Divisi�n en el m�todo cl�sico */}
      <div className="division-container">
        <div className="division-classic">
          <input
            type="text"
            value={dividendo}
            onChange={(e) => setDividendo(e.target.value)}
            placeholder="Dividendo"
            className="dividendo"
          />
          <span className="line-vertical"></span>
          <input
            type="text"
            value={divisor}
            onChange={(e) => setDivisor(e.target.value)}
            placeholder="Divisor"
            className="divisor"
          />
          <span className="line-horizontal"></span>
          <input
            type="text"
            value={resultado}
            onChange={(e) => setResultado(e.target.value)}
            placeholder="Resultado"
            className="resultado"
          />
        </div>
      </div>

      <button className="validate-button" onClick={handleSubmit}>
        Validar
      </button>
    </div>
  );
}

export default Escena20;
