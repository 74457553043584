import React from 'react';
import './css/escena13.css'; // Importar los estilos
import guacamayoImage from '../guacamayoBG.png'; // Asegúrate de tener la imagen del guacamayo
import backgroundMusic from '../audioFondo.mp3'; // Asegúrate de tener el audio de fondo


function Escena13({ avatar,onNext}) {
  



  const handleNext = () => {
    // Función para ir a la siguiente escena
  
    onNext();
  };

  return (
    <div className="escena13">


      {/* Niño o niña caminando hacia Medellín */}
      <img src={`/image/${avatar}.png`} alt="Niño caminando" className="nino-caminando7" />

      {/* Imagen del guacamayo */}
      <div className="guacamayo-container-movimiento">
        <img src={guacamayoImage} alt="Guacamayo" className="guacamayo7" />
        <p>Vámonos a Cali</p>
      </div>

      {/* Botón en el punto de Medellín */}
      <button className="boton-medellin" onClick={handleNext}>
        Ir a Cali
      </button>
    </div>
  );
}

export default Escena13;
