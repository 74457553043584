import React, { useState, useEffect, useRef } from 'react';
import './css/Escena9.css'; // Asegúrate de tener el archivo CSS en esta ruta
import guacamayoImage from '../guacamayoBG.png'; // Imagen del guacamayo

function Escena9({ avatar, onNext }) {
  const [selectedProblem, setSelectedProblem] = useState(null); // Almacena el problema seleccionado
  const [answer, setAnswer] = useState(''); // Almacena la respuesta ingresada
  const [correctAnswers, setCorrectAnswers] = useState([]); // Almacena respuestas correctas
  const [attempts, setAttempts] = useState(0); // Almacena la cantidad de intentos
  const [isSpinning, setIsSpinning] = useState(false);
  const hasSpokenRef = useRef(false); // Controlar si ya ha hablado
  const utteranceRef = useRef(null);
  const botonRef = useRef(null);
  const problems = [
    { question: '194.332 - 5.832', answer: 188500 },
    { question: '1.987.652 - 20.008', answer: 1967644 },
    { question: '358.762 - 16.798', answer: 341964 },
    { question: '684.970 - 82.945', answer: 602025 },
    { question: '1.089.432 - 63.849', answer: 1025583 },
    { question: '698.635 - 57.899', answer: 640736 },
  ];

  const options = ["194.332 - 5.832", "1.987.652 - 20.008", "358.762 - 16.798", "684.970 - 82.945", "1.089.432 - 63.849", "698.635 - 57.899"];

  let startAngle = 0;
  const arc = Math.PI / (options.length / 2);
  let spinTimeout = null;
  let spinTime = 0;
  let spinTimeTotal = 0;
  let spinAngleStart=0;

  const canvasRef = useRef(null); // Referencia para el canvas
  const ctxRef = useRef(null); // Referencia para el contexto de dibujo

    useEffect(() => {
  
  


    // Sonido de inicio de la escena
    const speak = (text) => {
      if (hasSpokenRef.current) return;
      const utterance = new SpeechSynthesisUtterance(text);
      utteranceRef.current = utterance;
      // Obtener todas las voces disponibles
      const voices = window.speechSynthesis.getVoices();

      // Buscar una voz en español (Latinoamérica)
      const vozLatina = voices.find(voice => voice.lang === 'es-MX' || voice.lang === 'es-US' || voice.lang === 'es-ES');

      // Si se encuentra una voz en español, asignarla
      if (vozLatina) {
        utterance.voice = vozLatina;
      }

      // Reproducir el mensaje
      window.speechSynthesis.speak(utterance);
      hasSpokenRef.current = true;
      // Al terminar de hablar, mover el avatar hacia el centro
        
    };speak('En la ruleta encontrarás una serie de sustracciones, escribe la respuesta correcta en el lugar correspondiente');
  }, []);




  useEffect(() => {
    const canvas = canvasRef.current; // Accede al canvas a través de la referencia
    if (canvas) {
      const ctx = canvas.getContext('2d'); // Obtén el contexto del canvas
      ctxRef.current = ctx; // Asigna el contexto a la referencia
      drawRouletteWheel(); // Dibuja la ruleta cuando el componente se monta
    }
  }, []); // Solo se ejecuta una vez cuando el componente se monta

  function byte2Hex(n) {
    const nybHexString = "0123456789ABCDEF";
    return String(nybHexString.substr((n >> 4) & 0x0F, 1)) + nybHexString.substr(n & 0x0F, 1);
  }

  function RGB2Color(r, g, b) {
    return '#' + byte2Hex(r) + byte2Hex(g) + byte2Hex(b);
  }

  function getColor(item, maxitem) {
    const phase = 0;
    const center = 128;
    const width = 127;
    const frequency = Math.PI * 2 / maxitem;

    const red = Math.sin(frequency * item + 2 + phase) * width + center;
    const green = Math.sin(frequency * item + 0 + phase) * width + center;
    const blue = Math.sin(frequency * item + 4 + phase) * width + center;

    return RGB2Color(red, green, blue);
  }

  function drawRouletteWheel() {
    const ctx = ctxRef.current; // Usa el contexto almacenado en la referencia
    if (ctx) {
      const outsideRadius = 200;
      const textRadius = 160;
      const insideRadius = 125;

      ctx.clearRect(0, 0, 500, 500); // Limpia el canvas antes de redibujar

      ctx.strokeStyle = "black";
      ctx.lineWidth = 2;

      ctx.font = 'bold 12px Helvetica, Arial';

      for (let i = 0; i < options.length; i++) {
        const angle = startAngle + i * arc;
        ctx.fillStyle = getColor(i, options.length);

        ctx.beginPath();
        ctx.arc(250, 250, outsideRadius, angle, angle + arc, false);
        ctx.arc(250, 250, insideRadius, angle + arc, angle, true);
        ctx.stroke();
        ctx.fill();

        ctx.save();
        ctx.shadowOffsetX = -1;
        ctx.shadowOffsetY = -1;
        ctx.shadowBlur = 0;
        ctx.shadowColor = "rgb(220,220,220)";
        ctx.fillStyle = "black";
        ctx.translate(250 + Math.cos(angle + arc / 2) * textRadius, 250 + Math.sin(angle + arc / 2) * textRadius);
        ctx.rotate(angle + arc / 2 + Math.PI / 2);
        const text = options[i];
        ctx.fillText(text, -ctx.measureText(text).width / 2, 0);
        ctx.restore();
      }

      // Flecha
      ctx.fillStyle = "black";
      ctx.beginPath();
      ctx.moveTo(250 - 4, 250 - (outsideRadius + 5));
      ctx.lineTo(250 + 4, 250 - (outsideRadius + 5));
      ctx.lineTo(250 + 4, 250 - (outsideRadius - 5));
      ctx.lineTo(250 + 9, 250 - (outsideRadius - 5));
      ctx.lineTo(250 + 0, 250 - (outsideRadius - 13));
      ctx.lineTo(250 - 9, 250 - (outsideRadius - 5));
      ctx.lineTo(250 - 4, 250 - (outsideRadius - 5));
      ctx.lineTo(250 - 4, 250 - (outsideRadius + 5));
      ctx.fill();
    }
  }

  function spin() {
    setIsSpinning(true);
    spinAngleStart = Math.random() * 10 + 10;
    spinTime = 0;
    spinTimeTotal = Math.random() * 3 + 4 * 1000;
    rotateWheel();
  }

  function rotateWheel() {
    spinTime += 30;
    if (spinTime >= spinTimeTotal) {
      stopRotateWheel();
      return;
    }
    const spinAngle = spinAngleStart - easeOut(spinTime, 0, spinAngleStart, spinTimeTotal);
    startAngle += (spinAngle * Math.PI) / 180;
    drawRouletteWheel();
    spinTimeout = setTimeout(rotateWheel, 30);
  }

  function stopRotateWheel() {
    clearTimeout(spinTimeout);
    const ctx = ctxRef.current;
    const degrees = (startAngle * 180) / Math.PI + 90;
    const arcd = (arc * 180) / Math.PI;
    const index = Math.floor((360 - (degrees % 360)) / arcd);
    ctx.save();
    ctx.font = 'bold 30px Helvetica, Arial';
    const text = options[index];
    ctx.fillText(text, 250 - ctx.measureText(text).width / 2, 250 + 10);
    ctx.restore();
    handleSpin(text);
  }

  function easeOut(t, b, c, d) {
    const ts = (t /= d) * t;
    const tc = ts * t;
    return b + c * (tc + -3 * ts + 3 * t);
  }

  const findProblem = (questionText) => {
    return problems.find(problem => problem.question === questionText);
  };

  const handleSpin = (text) => {
    const foundProblem = findProblem(text);
    setSelectedProblem(foundProblem);
    console.log(setSelectedProblem);
    setAnswer('');  
  };

  const handleAnswerChange = (e) => {
    setAnswer(e.target.value); // Actualiza el valor de la respuesta ingresada
    console.log(setAnswer);
  };

  const handleSubmit = () => {
  
    
    if (selectedProblem) {
      const isCorrect = parseInt(answer) === selectedProblem.answer;
      const successAudio = new Audio('/audio/correct.mp3'); // Sonido de respuesta correcta
      const failureAudio = new Audio('/audio/error.mp3'); // Sonido de respuesta incorrecta

      if (isCorrect) {
        alert('Correcto!!');
        setIsSpinning(false);
        successAudio.play();
        setCorrectAnswers([...correctAnswers, selectedProblem]);
      } else {
        
        alert('Incorrecto!!');
        failureAudio.play();
      }

      setAttempts(attempts + 1);
      if (correctAnswers.length === 2) {
      const finalSuccessAudio = new Audio('/audio/success.mp3');
      finalSuccessAudio.play();
        alert('¡Prueba superada!');
      if (utteranceRef.current) {
      window.speechSynthesis.cancel(); // Detener el audio si se está reproduciendo
    }
      }
    }
  };

  return (
    <div className="escena9">
      <img src={`/image/${avatar}.png`} alt="Niño y niña llegando a la actividad" className="nino-nina" />
      <div className="guacamayo-container">
        <img src={guacamayoImage} alt="Guacamayo" className="guacamayo9" />
        <p className="guacamayo-dialogo">
          En la ruleta encontrarás una serie de sustracciones, escribe la respuesta correcta en el lugar correspondiente.
        </p>
      </div>
     {!isSpinning && (
        <input ref={botonRef} type="button" value="GIRA LA RULETA!" onClick={spin} id='spin' />
      )}
      <canvas ref={canvasRef} id="canvas" width="500" height="500"></canvas>

      <div className="ruleta">
        {selectedProblem ? (
          <>
            <p className="problema">{selectedProblem.question} = ?</p>
            <input
              type="number"
              value={answer}
              onChange={handleAnswerChange}
              placeholder="Escribe tu respuesta"
            />
            <button onClick={handleSubmit} className="boton-validar">Validar respuesta</button>
          </>
        ) : (
          null
        )}
      </div>

      {attempts >= 3 && correctAnswers.length >= 3 && (
        <button onClick={onNext} className="boton-siguiente">
          <img src="/image/balon.webp" alt="Balón" className="balon-boton" />
        </button>
      )}
    </div>
  );
}

export default Escena9;
