import React, { useState,useRef,useEffect } from 'react';
import './css/escena22.css'; // Estilos correspondientes
import guacamayoImage from '../guacamayoBG.png';
function Escena22({ avatar,onNext }) {
  const hasSpokenRef = useRef(false); // Controlar si ya ha hablado
  const utteranceRef = useRef(null);
  const correctAnswers = ['0-2','1-2', '1-3', '1-4', '2-4','2-2', '3-4','4-4','3-1','3-2','3-3','4-1'];

  // Estado para las celdas seleccionadas
  const [selectedCells, setSelectedCells] = useState([]);
   const cellStyle = {
    border: '1px solid black',   // Líneas para dividir las celdas
    padding: '10px',             // Espacio interno en las celdas
  };
    
  useEffect(() => {
  
  


    // Sonido de inicio de la escena
    const speak = (text) => {
      if (hasSpokenRef.current) return;
      const utterance = new SpeechSynthesisUtterance(text);
      utteranceRef.current = utterance;
      // Obtener todas las voces disponibles
      const voices = window.speechSynthesis.getVoices();

      // Buscar una voz en español (Latinoamérica)
      const vozLatina = voices.find(voice => voice.lang === 'es-MX' || voice.lang === 'es-US' || voice.lang === 'es-ES');

      // Si se encuentra una voz en español, asignarla
      if (vozLatina) {
        utterance.voice = vozLatina;
      }

      // Reproducir el mensaje
      window.speechSynthesis.speak(utterance);
      hasSpokenRef.current = true;
      // Al terminar de hablar, mover el avatar hacia el centro
      
    };speak('Para finalizar, soluciona las divisiones y marca las respuestas correctas en la sopa de números.');
  }, []);
  
  
  // Maneja la selección de celdas, pero ahora usando las coordenadas fila-columna
  const handleCellClick = (rowIndex, cellIndex) => {
    const cellId = `${rowIndex}-${cellIndex}`; // Crear un identificador único para cada celda
    if (selectedCells.includes(cellId)) {
      // Si ya está seleccionada, la removemos
      setSelectedCells(selectedCells.filter((cell) => cell !== cellId));
    } else {
      // Si no está seleccionada, la añadimos
      setSelectedCells([...selectedCells, cellId]);
    }
  };

  // Validar respuestas
  const validateAnswers = () => {
    const allCorrectSelected = correctAnswers.every((answer) => selectedCells.includes(answer));

 
  const noIncorrectSelected = selectedCells.every((cell) => correctAnswers.includes(cell));
    
    if (allCorrectSelected && noIncorrectSelected) {
      const successAudio = new Audio('/audio/success.mp3');
      successAudio.play();
      alert('¡Prueba superada!');
      onNext(); // Pasar a la siguiente escena
    } else {
      const failureAudio = new Audio('/audio/error.mp3');
      failureAudio.play();
      alert('Una o más respuestas son incorrectas. Inténtalo de nuevo.');
    }
  };

  return (
    <div className="escena22">
    
      
          <table style={{ margin: 'auto', textAlign: 'left'  ,borderCollapse: 'collapse',  backgroundColor: '#f0f0f0' }}>
      <tbody>
        <tr>
          <td style={cellStyle}>2451 ÷ 3 </td>
          <td style={cellStyle}>918 ÷ 9 </td>
          <td style={cellStyle}>1512 ÷ 56 </td>
        </tr>
        <tr>
          <td style={cellStyle}>980 ÷ 20 </td>
          <td style={cellStyle}>1435 ÷ 5 </td>
        </tr>
      </tbody>
    </table>
  
      {/* Guacamayo dando la instrucción */}
      <div className="guacamayo-dialogo">
        <img src={guacamayoImage} alt="Guacamayo" />
        <p>Para finalizar, soluciona las divisiones y marca las respuestas correctas en la sopa de números.</p>
      </div>

      {/* Sopa de Números */}
      <div className="sopa-de-numeros">
        <table>
          <tbody>
            {/* Muestra la cuadrícula con números */}
            {[
              ['1', '0', '7', '1', '4'],
              ['7', '8', '8', '1', '7'],
              ['1', '5', '2', '5', '1'],
              ['6', '7', '4', '9', '0'],
              ['9', '2', '5', '1', '2'],
            ].map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    className={selectedCells.includes(`${rowIndex}-${cellIndex}`) ? 'selected' : ''}
                    onClick={() => handleCellClick(rowIndex, cellIndex)}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Botón para validar las respuestas */}
      <button onClick={validateAnswers} className="boton-validar">
        Validar respuestas
      </button>
    </div>
  );
}

export default Escena22;
