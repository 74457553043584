import React, { createContext, useState, useEffect } from 'react';

export const AudioContext = createContext();

export const AudioProvider = ({ children }) => {
  const [audio] = useState(new Audio(process.env.PUBLIC_URL + '/audio/audioFondo.mp3')); // Cambia la ruta por la del audio que quieres usar
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    audio.loop = true; // Para que el audio se repita en bucle
    audio.volume = 0.004  ; // Ajusta el volumen seg�n prefieras

    // Comienza a reproducir si est� en estado "playing"
    if (isPlaying) {
      audio.play();
       audio.play().catch(error => console.log('Error al reproducir el audio:', error));
    } else {
      audio.pause();
    }

    // Cleanup al desmontar el componente
    return () => {
      audio.pause();
    };
  }, [isPlaying, audio]);

  return (
    <AudioContext.Provider value={{ isPlaying, setIsPlaying }}>
      {children}
    </AudioContext.Provider>
  );
};
