import React, { useEffect ,useContext} from 'react';
import './css/escena23.css'; // Asegúrate de tener los estilos aplicados
import guacamayoImage from '../guacamayoBG.png'; // Ruta de la imagen del guacamayo con la copa
import trophy from '../game-trophy.png';
import { AudioContext } from '../AudioContext';
function Escena23({ avatar,onFinish }) {
  const { isPlaying, setIsPlaying } = useContext(AudioContext);
  
  useEffect(() => {
    // Reproducir el sonido de celebración al cargar la escena
    setIsPlaying(false);
     const finalAudio = new Audio ('audio/final.mp3')
    finalAudio.play().catch((error) => {
    console.error('Error al iniciar la reproducción:', error);
  });
  }, []);
  
  
  const mostrarAlerta = () => {
  alert('¿Quieres mas?,¡Proximamente!');
};
  return (
    <div className="escena23">
  
      <h1 className ='escena23H1'>¡Qué bien! Has superado las pruebas  </h1>
      {/* Niño y niña llegando al punto inicial */}
      <div className="characters">
        <img src={`/image/${avatar}.png`} alt="Niño y niña llegando" className="characters-image" />
      </div>
      
      <div className='copa-container'>
       <img src={trophy} alt="copa" className="copaa" />
      </div>
      
   
      {/* Guacamayo con la copa */}
      <div className="guacamayo-copa">
        <img src={guacamayoImage} alt="Guacamayo con la copa" className="guacamayo-image" />
      
      </div>

      {/* Botón para finalizar la actividad */}
      <button onClick={mostrarAlerta} className="finish-button">Finalizar</button>
    </div>
  );
}

export default Escena23;
