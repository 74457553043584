import React, { useState,useRef,useEffect } from 'react';
import './css/escena21.css'; // Asegúrate de tener el archivo CSS vinculado
import balones7 from '../7balones.png';
import balones21 from '../21balones.png';
import guacamayoImage from '../guacamayoBG.png';
function Escena21({ avatar,onNext }) {
  const hasSpokenRef = useRef(false); // Controlar si ya ha hablado
  const utteranceRef = useRef(null);
  const [respuesta, setRespuesta] = useState(''); // Para guardar la respuesta del jugador
  const [mensaje, setMensaje] = useState(''); // Mensaje de retroalimentación

  const validarRespuesta = () => {
    if (respuesta === '3') {
      const successAudio = new Audio('/audio/success.mp3');
      successAudio.play();
      setMensaje('¡Prueba superada!');

      // Llamar a la siguiente escena después de un tiempo
      setTimeout(() => {
        onNext();
      }, 2000);
    } else {
      const errorAudio = new Audio('/audio/error.mp3');
      errorAudio.play();
      setMensaje('Respuesta incorrecta. Inténtalo de nuevo.');
    }
  };
  
    useEffect(() => {
  
  


    // Sonido de inicio de la escena
    const speak = (text) => {
      if (hasSpokenRef.current) return;
      const utterance = new SpeechSynthesisUtterance(text);
      utteranceRef.current = utterance;
      // Obtener todas las voces disponibles
      const voices = window.speechSynthesis.getVoices();

      // Buscar una voz en español (Latinoamérica)
      const vozLatina = voices.find(voice => voice.lang === 'es-MX' || voice.lang === 'es-US' || voice.lang === 'es-ES');

      // Si se encuentra una voz en español, asignarla
      if (vozLatina) {
        utterance.voice = vozLatina;
      }

      // Reproducir el mensaje
      window.speechSynthesis.speak(utterance);
      hasSpokenRef.current = true;
      // Al terminar de hablar, mover el avatar hacia el centro
      
    };speak('Al lado derecho encuentras unos balones, ¿cuántos conjuntos iguales al de la izquierda puedes agrupar? Escribe en el cuadro.');
  }, []);

  return (
    <div className="escena21">

      {/* Imagen 1: Niño y niña */}
      <div className="ninos">
        <img src={`/image/${avatar}.png`} alt="Niño y niña llegando" />
      </div>

      {/* Imagen 2: Guacamayo */}
      <div className="guacamayo21">
        <img src={guacamayoImage} alt="Guacamayo" />
        <p>Al lado derecho encuentras unos balones, ¿cuántos conjuntos iguales al de la izquierda puedes agrupar? Escribe en el cuadro.</p>
      </div>

      {/* Imagen 3: Balones y cuadro para respuesta */}
      <div className="contenedor-balones">
        <div className="balones-izquierda">
          <img src={balones7} alt="7 Balones" />
        </div>
        <div className="cuadro-respuesta">
          <input
            type="text"
            value={respuesta}
            onChange={(e) => setRespuesta(e.target.value)}
            placeholder="Respuesta"
          />
        </div>
        <div className="balones-derecha">
          <img src={balones21} alt="21 Balones" />
        </div>
      </div>

      {/* Botón para validar */}
      <button onClick={validarRespuesta} className="boton-validar">
        Validar
      </button>

      {/* Mostrar el mensaje de retroalimentación */}
      {mensaje && <p className="mensaje">{mensaje}</p>}

      {/* Botón para pasar a la siguiente escena */}
      {mensaje === '¡Prueba superada!' && (
        <button onClick={onNext} className="boton-siguiente">
          Ir a la siguiente escena
        </button>
      )}
    </div>
  );
}

export default Escena21;
