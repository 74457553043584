import React, { useEffect, useState ,useRef } from 'react';
import '../App.css'; // Asegúrate de tener un archivo de estilos para Barranquilla
import guacamayoBG from '../guacamayoBG.png';
function EstadioBarranquilla({ avatar, onNext }) { // Recibe el avatar seleccionado como prop
const [avatarPosition, setAvatarPosition] = useState('start'); // Para animar el movimiento del avatar
const hasSpokenRef = useRef(false); // Controlar si ya ha hablado
const utteranceRef = useRef(null); // Referencia al SpeechSynthesisUtterance para detener el audio
const soundRef = useRef(false);
  useEffect(() => {
  
    if(!soundRef.current){
     
      const bullaAudio = new Audio('/audio/bulla.mp3'); // Ruta relativa al archivo de audio en public/audio
      bullaAudio.play().catch((error) => {
    console.error('Error al iniciar la reproducción:', error);
  }); // Reproducir el sonido de bulla
      soundRef.current = true;
    } 
    // Función para que el guacamayo hable
    const speak = (text) => {
      if (hasSpokenRef.current) return;
      const utterance = new SpeechSynthesisUtterance(text);
      utteranceRef.current = utterance;
      // Obtener todas las voces disponibles
      const voices = window.speechSynthesis.getVoices();
  
      // Buscar una voz en español (Latinoamérica)
      const vozLatina = voices.find(voice => voice.lang === 'es-MX' || voice.lang === 'es-US' || voice.lang === 'es-ES');

      // Si se encuentra una voz en español, asignarla
      if (vozLatina) {
        utterance.voice = vozLatina;
      }

      // Reproducir el mensaje
      window.speechSynthesis.speak(utterance);
      hasSpokenRef.current = true;
      // Al terminar de hablar, mover el avatar hacia el centro
      
      utterance.onend = () => {
        setAvatarPosition('center'); // Cambia la posición del avatar al centro
      };
    };
  speak('Wow, qué calor que hace aquí, este es un buen lugar para comenzar nuestro campeonato. En este estadio, vas a encontrar las primeras actividades que corresponden a la SUMA, ¿qué tal te va con eso?, pon a prueba tus conocimientos, dirígete al centro de la cancha vamos a comenzar.');
 
    
    }, []);
     function isPlaying(audio) {
  return !audio.paused; // Retorna true si se está reproduciendo
}
   


     const handleNext = () => {
    if (utteranceRef.current) {
      window.speechSynthesis.cancel(); // Detener el audio si se está reproduciendo
    }
    onNext(); // Pasar a la siguiente escena
  };
  

  return (
    <div className="estadio-barranquilla">
      <img
        src={guacamayoBG }
        alt="Guacamayo"
        className="guacamayo"
      />
      <img
        src={`/image/${avatar}.png`} // Mostrar el avatar seleccionado en la escena anterior
        alt="Avatar del Jugador"
        className={`avatar ${avatarPosition}`}
      />

      {/* Botón en forma de balón de fútbol */}
      <button className="boton-balon" onClick={handleNext}>
        <img src="/image/balon.webp" alt="Balón de fútbol" />
      </button>
    </div>
  );
}

export default EstadioBarranquilla;


