import React, { useState, useRef,useEffect } from 'react';
import './css/escena6.css'; // Ensure styles are applied
import guacamayoImage from '../guacamayoBG.png'; // Imagen del guacamayo
function Escena17({ avatar,onNext }) {
  const hasSpokenRef = useRef(false); // Controlar si ya ha hablado
  const utteranceRef = useRef(null); // Referencia al SpeechSynthesisUtterance para detener el audio
  const [showBall, setShowBall] = useState(false);
  const [ballPosition, setBallPosition] = useState('start');
  const [goalScored, setGoalScored] = useState(false);
  const ballRef = useRef(null); // Crear referencia para el balón
  
  
   useEffect(() => {
    if (showBall) {
      const ball = ballRef.current;
      if (ball) {
        console.log('Ball found:', ball);
        setBallPosition('moving'); 
        setTimeout(() => {
          setGoalScored(true);
          const cheeringAudio = new Audio(process.env.PUBLIC_URL + '/audio/bulla.mp3');
          cheeringAudio.play();
                          }, 2000); // Esperar 2 segundos para simular el balón llegando al arco
      } else {
        console.log('Ball not found');
      }
    }
  }, [showBall]); // Ejecuta este efecto solo cuando `showBall` cambie a `true`

  const handleKick = () => {
    setShowBall(true); // Mostrar el balón
  };
  
  
 
    
    
    

 
  
  useEffect(() => {
  if (showBall) {
    const ball = ballRef.current;
    if (ball) {
      console.log('Ball found:', ball);
      setBallPosition('moving'); // Mueve el balón aquí
    } else {
      console.log('Ball not found');
    }
  }
}, [showBall]); // Ejecuta este efecto solo cuando `showBall` cambie a `true`

  
  useEffect(() => {
  
  


    // Sonido de inicio de la escena
    const speak = (text) => {
      if (hasSpokenRef.current) return;
      const utterance = new SpeechSynthesisUtterance(text);
      utteranceRef.current = utterance;
      // Obtener todas las voces disponibles
      const voices = window.speechSynthesis.getVoices();

      // Buscar una voz en español (Latinoamérica)
      const vozLatina = voices.find(voice => voice.lang === 'es-MX' || voice.lang === 'es-US' || voice.lang === 'es-ES');

      // Si se encuentra una voz en español, asignarla
      if (vozLatina) {
        utterance.voice = vozLatina;
      }

      // Reproducir el mensaje
      window.speechSynthesis.speak(utterance);
      hasSpokenRef.current = true;
      // Al terminar de hablar, mover el avatar hacia el centro
        
    };speak('Muy bien has terminado esta visita, lanza el balón al arco para que puedas continuar al siguiente estadio ubicado en la ciudad de Bogotá.');
  }, []);

  return (
    <div className="escena-medellin">
      

      {/* Guacamayo dando instrucciones */}
      <div className="dialog-box">
        <img src={guacamayoImage} alt="Guacamayo" className="guacamayoBG" />
        <p>Muy bien, has terminado esta visita. Lanza el balón al arco para continuar al siguiente estadio ubicado en la ciudad de Bogotá.</p>
      </div>

      {/* Niños y el balón que será lanzado */}
      <div className="players">
        <img src={`/image/${avatar}.png`} alt="Kids kicking the ball" className="kids" />
        {!showBall && (
          <button className="kick-button" onClick={handleKick}>Lanzar balón</button>
        )}
        {showBall && (
          <img ref={ballRef} id="ball" src="/image/balon.webp" alt="Ball" className={`ball ${ballPosition}`} />
        )}
      </div>

      {/* Botón para proceder al mapa después de marcar el gol */}
      {goalScored && (
        <button className="goal-button" onClick={onNext}>Ir al mapa de Bogotá</button>
      )}
    </div>
  );
}

export default Escena17;
