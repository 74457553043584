// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../estadio2.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Escena10 {
  text-align: center;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); /* Fondo de la cancha */
  display: flex;
   background-size: cover;
  background-position: center;
  height: 100vh;
  padding: 20px;
}

.container {
  display: flex;
  justify-content: space-around;
  margin-top: 170px;
}

.questions, .answers {
  width: 80%;
}

.questions {
  margin-right: 50px; /* Espacio a la derecha de las preguntas */
}
.line{
stroke : red;
}
h3{
  color:white;
} 
.answers {
  margin-left: 50px; /* Espacio a la izquierda de las respuestas */
}

.question-box  {
  margin: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f4f4f4;
}
.answer-box{
 margin: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f4f4f4;
  height:40px;
  font-weight:bold;
}

.svg-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/css/Escena10.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yDAA4C,EAAE,uBAAuB;EACrE,aAAa;GACZ,sBAAsB;EACvB,2BAA2B;EAC3B,aAAa;EACb,aAAa;AACf;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB,EAAE,0CAA0C;AAChE;AACA;AACA,YAAY;AACZ;AACA;EACE,WAAW;AACb;AACA;EACE,iBAAiB,EAAE,6CAA6C;AAClE;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,yBAAyB;AAC3B;AACA;CACC,YAAY;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oBAAoB;AACtB","sourcesContent":[".Escena10 {\r\n  text-align: center;\r\n  background-image: url('../../estadio2.webp'); /* Fondo de la cancha */\r\n  display: flex;\r\n   background-size: cover;\r\n  background-position: center;\r\n  height: 100vh;\r\n  padding: 20px;\r\n}\r\n\r\n.container {\r\n  display: flex;\r\n  justify-content: space-around;\r\n  margin-top: 170px;\r\n}\r\n\r\n.questions, .answers {\r\n  width: 80%;\r\n}\r\n\r\n.questions {\r\n  margin-right: 50px; /* Espacio a la derecha de las preguntas */\r\n}\r\n.line{\r\nstroke : red;\r\n}\r\nh3{\r\n  color:white;\r\n} \r\n.answers {\r\n  margin-left: 50px; /* Espacio a la izquierda de las respuestas */\r\n}\r\n\r\n.question-box  {\r\n  margin: 15px;\r\n  padding: 10px;\r\n  border: 1px solid #ddd;\r\n  border-radius: 5px;\r\n  cursor: pointer;\r\n  background-color: #f4f4f4;\r\n}\r\n.answer-box{\r\n margin: 15px;\r\n  padding: 10px;\r\n  border: 1px solid #ddd;\r\n  border-radius: 5px;\r\n  cursor: pointer;\r\n  background-color: #f4f4f4;\r\n  height:40px;\r\n  font-weight:bold;\r\n}\r\n\r\n.svg-lines {\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  pointer-events: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
