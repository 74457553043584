import React, { useState,useRef,useEffect } from 'react';
import './css/escena11.css'; // Importa los estilos
import guacamayoImage from '../guacamayoBG.png'; // Imagen del guacamayo
import restaImage from '../resta.png'
function Escena11({ avatar, onNext }) {
const isCorrect = useState(false);
  
  const hasSpokenRef = useRef(false); // Controlar si ya ha hablado
  const utteranceRef = useRef(null);
  
  const [inputValues, setInputValues] = useState({
    input1: '',
    input2: '',
    input3: '',
    input4: '',
    input5: '',
    input6: '',
    input7: '',
    input8: '',
    input9: '',
    input10: '',
    input11: '',
    input12: '',
    input13: '',
    input14: '',
    input15: '',
    input16: '',
    input17: '',
    input18: '',
  });
  

  const correctAnswers = {
    input1: '0',
    input2: '4',
    input3: '5',
    input4: '9',
    input5: '2',
    input6: '8',
    input7: '6',
    input8: '3',
    input9: '8',
    input10: '7',
    input11: '3',
    input12: '5',
    input13: '0',
    input14: '2',
    input15: '8',
    input16: '8',
    input17: '0',
    input18: '1',
    
  };
  
  
    
  useEffect(() => {
  
  


    // Sonido de inicio de la escena
    const speak = (text) => {
      if (hasSpokenRef.current) return;
      const utterance = new SpeechSynthesisUtterance(text);
      utteranceRef.current = utterance;
      // Obtener todas las voces disponibles
      const voices = window.speechSynthesis.getVoices();

      // Buscar una voz en español (Latinoamérica)
      const vozLatina = voices.find(voice => voice.lang === 'es-MX' || voice.lang === 'es-US' || voice.lang === 'es-ES');

      // Si se encuentra una voz en español, asignarla
      if (vozLatina) {
        utterance.voice = vozLatina;
      }

      // Reproducir el mensaje
      window.speechSynthesis.speak(utterance);
      hasSpokenRef.current = true;
      // Al terminar de hablar, mover el avatar hacia el centro
        
    };speak('Bien, ahora la actividad consiste en que llenes los espacios de las restas para que las operaciones sean correctas.');
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Actualiza el valor del input correspondiente en el objeto inputValues
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    let allCorrect = true;

    // Verificar cada input con su valor correcto
    for (let key in inputValues) {
      if (inputValues[key] !== correctAnswers[key]) {
        console.log(inputValues[key]);
        console.log(correctAnswers[key]);
        allCorrect = false;
        
      }
    }

    if (allCorrect) {
      alert('¡Todos los valores son correctos!');
      if (utteranceRef.current) {
      window.speechSynthesis.cancel(); // Detener el audio si se está reproduciendo
    }
    const successAudio = new Audio('/audio/success.mp3'); // Ruta relativa al archivo en public/audio
      successAudio.play();
      onNext();
    } else {
     const failureAudio = new Audio('/audio/error.mp3'); // Ruta relativa al archivo en public/audio
      failureAudio.play();
      alert('Algunos valores son incorrectos. Inténtalo de nuevo.');
    }
  };


  return (
    <div className="escena11">

      {/* Niño y niña */}
      <img src={`/image/${avatar}.png`} alt="Niño y niña" className="nino-nina" />

      {/* Guacamayo */}
      <div className="guacamayo-container">
      <p className="guacamayo-dialogo">
          Bien, ahora la actividad consiste en que llenes los espacios de las restas para que las operaciones sean correctas.
        </p>
        <img src={guacamayoImage} alt="Guacamayo" className="guacamayo11" />
        
      </div>

      {/* Operaciones con campos en blanco */}
      <div className="operations-container">
      <div className ='row'> 
      <div className="grid-container">
      <div className="grid-item hidden"></div>
        <div className="grid-item">3</div>
        <div className="grid-item-input"><input  type="number" name="input1" onChange={handleChange}placeholder="?" value={inputValues.input1} className="input-operation" /></div>
        <div className="grid-item">5</div>
        <div className="grid-item">6</div>
        <div className="grid-item-input"><input type="number" name="input2" onChange={handleChange}placeholder="?" value={inputValues.input2} className="input-operation" /></div>
        <div className="grid-item">4</div>
        {/* Siguiente Fila*/}
        <div className="grid-image">
          <img src={restaImage}   />
        </div>
        <div className="grid-item hidden"></div>
        <div className="grid-item">9</div>
        <div className="grid-item-input"><input type="number" name="input3"onChange={handleChange}placeholder="?"  value={inputValues.input3} className="input-operation"/></div>
        <div className="grid-item">7</div>
        <div className="grid-item">8</div>
        <div className="grid-item-input"><input type="number" name="input4" onChange={handleChange}placeholder="?" value={inputValues.input4} className="input-operation"/></div>
            {/* Fila de líneas (hr) */}
        <div className="grid-item hidden"></div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>        
        {/* Fila Resultado*/}
          <div className="grid-item hidden"></div>
        <div className="grid-item-input"><input  type="number" name="input5" onChange={handleChange}placeholder="?" value={inputValues.input5} className="input-operation"/></div>
        <div className="grid-item">0</div>
        <div className="grid-item">9</div>
        <div className="grid-item-input"><input  type="number" name="input6" onChange={handleChange}placeholder="?" value={inputValues.input6} className="input-operation"/></div>
        <div className="grid-item">5</div>
        <div className="grid-item">5</div>

    
    
   
        
    </div>
    {/*Segunda resta */}
    
    <div className="grid-container">
      <div className="grid-item hidden"></div>
        <div className="grid-item-input"><input type="number" name="input7" onChange={handleChange}placeholder="?" value={inputValues.input7} className="input-operation"/></div>
        <div className="grid-item">4</div>
        <div className="grid-item">4</div>
        <div className="grid-item">2</div>
        <div className="grid-item-input"><input type="number" name="input8"  onChange={handleChange}placeholder="?" value={inputValues.input8} className="input-operation" /></div>
        <div className="grid-item-input"><input  type="number" name="input9" onChange={handleChange}placeholder="?" value={inputValues.input9}  className="input-operation" /></div>
        {/* Siguiente Fila*/}
        <div className="grid-image" >
          <img src={restaImage}   />
        </div>
        <div className="grid-item">4</div>
        <div className="grid-item-input"><input type="number" name="input10" onChange={handleChange}placeholder="?" value={inputValues.input10}  className="input-operation"/></div>
        <div className="grid-item">8</div>
       <div className="grid-item-input"><input  type="number" name="input11" onChange={handleChange}placeholder="?" value={inputValues.input11} className="input-operation"/></div>
        <div className="grid-item">4</div>
        <div className="grid-item">9</div>
            {/* Fila de líneas (hr) */}
        <div className="grid-item hidden"></div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>        
        {/* Fila Resultado*/}
          <div className="grid-item hidden"></div>
        <div className="grid-item">1</div>
        <div className="grid-item">6</div>
        <div className="grid-item-input"><input  type="number" name="input12" onChange={handleChange}placeholder="?" value={inputValues.input12} className="input-operation"/></div>
        <div className="grid-item">8</div>
        <div className="grid-item">8</div>
        <div className="grid-item">9</div>

    
    
   
           
    </div> 
    </div>
    <br/>
    {/*Tercera Resta*/}
    <div className ='row baja'>
    <div className="grid-container">
      <div className="grid-item hidden"></div>
        <div className="grid-item">7</div>
        <div className="grid-item">9</div>
        <div className="grid-item">5</div>
        <div className="grid-item">3</div>
        <div className="grid-item-input"><input type="number" name="input13" onChange={handleChange}placeholder="?" value={inputValues.input13} className="input-operation" /></div>
        <div className="grid-item">0</div>
        {/* Siguiente Fila*/}
        <div className="grid-image">
          <img src={restaImage}   />
        </div>
        <div className="grid-item-input"><input type="number" name="input14" onChange={handleChange}placeholder="?" value={inputValues.input14} className="input-operation"/></div>
        <div className="grid-item">9</div>
        <div className="grid-item">3</div>
       <div className="grid-item-input"><input type="number" name="input15" onChange={handleChange}placeholder="?" value={inputValues.input15} className="input-operation"/></div>
        <div className="grid-item">8</div>
        <div className="grid-item-input"><input type="number" name="input16" onChange={handleChange}placeholder="?" value={inputValues.input16} className="input-operation"/></div>
            {/* Fila de líneas (hr) */}
        <div className="grid-item hidden"></div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>
        <div className="grid-item">
          <hr className="grid-line" />
        </div>        
        {/* Fila Resultado*/}
          <div className="grid-item hidden"></div>
        <div className="grid-item">5</div>
        <div className="grid-item-input"><input type="number" name="input17" onChange={handleChange}placeholder="?" value={inputValues.input17} className="input-operation"/></div>
        <div className="grid-item-input"><input type="number" name="input18" onChange={handleChange}placeholder="?" value={inputValues.input18} className="input-operation"/></div>
        <div className="grid-item">4</div>
        <div className="grid-item">1</div>
        <div className="grid-item">2</div>

    
    
   
    </div>     
    </div>
    

       
      </div>

      {/* Botón de verificación */}
      <button className="check-button" onClick={handleSubmit}>
        Verificar respuestas
      </button>

      
    </div>
  );
}

export default Escena11;
