// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../estadioBogota3.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.escena22 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); /* Fondo de la cancha */
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.guacamayo-dialogo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.guacamayo-dialogo img {
  width: 150px;
  margin-right: 20px;
}

.sopa-de-numeros {
  margin-top: 20px;
}

.sopa-de-numeros table {
  border-collapse: collapse;
  margin: 0 auto;
}

.sopa-de-numeros td {
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #333;
  font-size: 24px;
  cursor: pointer;
  background-color: white;

}

.sopa-de-numeros td.selected {
  background-color: #1e90ff;
}

.boton-validar {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.boton-validar:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/components/css/escena22.css"],"names":[],"mappings":"AAAA;EACE,yDAAoD,EAAE,uBAAuB;EAC7E,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,sBAAsB;EACtB,eAAe;EACf,eAAe;EACf,uBAAuB;;AAEzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".escena22 {\r\n  background-image: url('../../estadioBogota3.webp  '); /* Fondo de la cancha */\r\n  background-size: cover;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 100vh;\r\n}\r\n\r\n.guacamayo-dialogo {\r\n  display: flex;\r\n  flex-direction: row;\r\n  align-items: center;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.guacamayo-dialogo img {\r\n  width: 150px;\r\n  margin-right: 20px;\r\n}\r\n\r\n.sopa-de-numeros {\r\n  margin-top: 20px;\r\n}\r\n\r\n.sopa-de-numeros table {\r\n  border-collapse: collapse;\r\n  margin: 0 auto;\r\n}\r\n\r\n.sopa-de-numeros td {\r\n  width: 50px;\r\n  height: 50px;\r\n  text-align: center;\r\n  vertical-align: middle;\r\n  border: 1px solid #333;\r\n  font-size: 24px;\r\n  cursor: pointer;\r\n  background-color: white;\r\n\r\n}\r\n\r\n.sopa-de-numeros td.selected {\r\n  background-color: #1e90ff;\r\n}\r\n\r\n.boton-validar {\r\n  margin-top: 20px;\r\n  padding: 10px 20px;\r\n  font-size: 18px;\r\n  background-color: #007bff;\r\n  color: white;\r\n  border: none;\r\n  border-radius: 5px;\r\n  cursor: pointer;\r\n}\r\n\r\n.boton-validar:hover {\r\n  background-color: #0056b3;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
