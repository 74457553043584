import React, { useState, useEffect, useContext } from 'react';
import Intro from './components/Intro';
import EstadioBarranquilla from './components/EstadioBarranquilla';
import EstadioBarranquillaSumas from './components/EstadioBarranquillaSumas';
import Escena4 from './components/Escena4';
import Escena5 from './components/Escena5';
import Escena6 from './components/escena6';
import Escena7 from './components/Escena7';
import Escena8 from './components/Escena8';
import Escena9 from './components/Escena9';
import Escena10 from './components/Escena10';
import Escena11 from './components/Escena11';
import Escena12 from './components/Escena12';
import Escena13 from './components/Escena13';
import Escena14 from './components/Escena14';
import Escena15 from './components/Escena15';
import Escena16 from './components/Escena16';
import Escena17 from './components/Escena17';
import Escena18 from './components/Escena18';
import Escena19 from './components/Escena19';
import Escena20 from './components/Escena20';
import Escena21 from './components/Escena21';
import Escena22 from './components/Escena22';
import Escena23 from './components/Escena23';
import { AudioContext } from './AudioContext';

function App() {
  const [escena, setEscena] = useState('intro');
  const [avatar, setAvatar] = useState(null);
  const { setIsPlaying } = useContext(AudioContext);

  const seleccionarAvatar = (avatarSeleccionado) => {
    setAvatar(avatarSeleccionado);
    setEscena('barranquilla');
    setIsPlaying(true);
  };

  const escenaSiguiente = {
    'intro': () => seleccionarAvatar('nino-azul-rojo'),
    'barranquilla': () => setEscena('barranquillaSumas'),
    'barranquillaSumas': () => setEscena('escena4'),
    'escena4': () => setEscena('escena5'),
    'escena5': () => setEscena('escena6'),
    'escena6': () => setEscena('escena7'),
    'escena7': () => setEscena('escena8'),
    'escena8': () => setEscena('escena9'),
    'escena9': () => setEscena('escena10'),
    'escena10': () => setEscena('escena11'),
    'escena11': () => setEscena('escena12'),
    'escena12': () => setEscena('escena13'),
    'escena13': () => setEscena('escena14'),
    'escena14': () => setEscena('escena15'),
    'escena15': () => setEscena('escena16'),
    'escena16': () => setEscena('escena17'),
    'escena17': () => setEscena('escena18'),
    'escena18': () => setEscena('escena19'),
    'escena19': () => setEscena('escena20'),
    'escena20': () => setEscena('escena21'),
    'escena21': () => setEscena('escena22'),
    'escena22': () => setEscena('escena23'),
  };

  return (
    <div className="App">
      {escena === 'intro' && <Intro onNext={seleccionarAvatar} />}
      {escena === 'barranquilla' && <EstadioBarranquilla avatar={avatar} onNext={() => setEscena('barranquillaSumas')} />}
      {escena === 'barranquillaSumas' && <EstadioBarranquillaSumas avatar={avatar} onNext={() => setEscena('escena4')} />}
      {escena === 'escena4' && <Escena4 avatar={avatar} onNext={() => setEscena('escena5')} />}
      {escena === 'escena5' && <Escena5 avatar={avatar} onNext={() => setEscena('escena6')} />}
      {escena === 'escena6' && <Escena6 avatar={avatar} onNext={() => setEscena('escena7')} />}
      {escena === 'escena7' && <Escena7 avatar={avatar} onNext={() => setEscena('escena8')} />}
      {escena === 'escena8' && <Escena8 avatar={avatar} onNext={() => setEscena('escena9')} />}
      {escena === 'escena9' && <Escena9 avatar={avatar} onNext={() => setEscena('escena10')} />}
      {escena === 'escena10' && <Escena10 avatar={avatar} onNext={() => setEscena('escena11')} />}
      {escena === 'escena11' && <Escena11 avatar={avatar} onNext={() => setEscena('escena12')} />}
      {escena === 'escena12' && <Escena12 avatar={avatar} onNext={() => setEscena('escena13')} />}
      {escena === 'escena13' && <Escena13 avatar={avatar} onNext={() => setEscena('escena14')} />}
      {escena === 'escena14' && <Escena14 avatar={avatar} onNext={() => setEscena('escena15')} />}
      {escena === 'escena15' && <Escena15 avatar={avatar} onNext={() => setEscena('escena16')} />}
      {escena === 'escena16' && <Escena16 avatar={avatar} onNext={() => setEscena('escena17')} />}
      {escena === 'escena17' && <Escena17 avatar={avatar} onNext={() => setEscena('escena18')} />}
      {escena === 'escena18' && <Escena18 avatar={avatar} onNext={() => setEscena('escena19')} />}
      {escena === 'escena19' && <Escena19 avatar={avatar} onNext={() => setEscena('escena20')} />}
      {escena === 'escena20' && <Escena20 avatar={avatar} onNext={() => setEscena('escena21')} />}
      {escena === 'escena21' && <Escena21 avatar={avatar} onNext={() => setEscena('escena22')} />}
      {escena === 'escena22' && <Escena22 avatar={avatar} onNext={() => setEscena('escena23')} />}
      {escena === 'escena23' && <Escena23 avatar={avatar} />}
    </div>
  );
}

export default App;
