import React, { useState,useEffect,useRef } from 'react';
import './css/Escena5.css';
import guacamayoBG from '../guacamayoBG.png';
function Escena5({ avatar,onNext}) {
  const hasSpokenRef = useRef(false); // Controlar si ya ha hablado
  const utteranceRef = useRef(null);
  const [selectedProducts, setSelectedProducts] = useState({
    bebida: null,
    paquetico: null,
    dulce: null,
  });
  const [avatarPosition] = useState('start');
  const bebidas = [6030, 6280, 5285, 3565].sort(() => Math.random() - 0.5);
  const paqueticos = [5390, 6620, 6738, 2563].sort(() => Math.random() - 0.5);
  const dulces = [3580, 2681, 2977, 4890].sort(() => Math.random() - 0.5);


  const correctCombinations = [
    { bebida: 6030, paquetico: 5390, dulce: 3580 },
    { bebida: 5285, paquetico: 6738, dulce: 2977 }
  ];
  
  useEffect(() => {
  
  


    // Sonido de inicio de la escena
    const speak = (text) => {
      if (hasSpokenRef.current) return;
      const utterance = new SpeechSynthesisUtterance(text);
      utteranceRef.current = utterance;
      // Obtener todas las voces disponibles
      const voices = window.speechSynthesis.getVoices();

      // Buscar una voz en español (Latinoamérica)
      const vozLatina = voices.find(voice => voice.lang === 'es-MX' || voice.lang === 'es-US' || voice.lang === 'es-ES');

      // Si se encuentra una voz en español, asignarla
      if (vozLatina) {
        utterance.voice = vozLatina;
      }

      // Reproducir el mensaje
      window.speechSynthesis.speak(utterance);
      hasSpokenRef.current = true;
      // Al terminar de hablar, mover el avatar hacia el centro
      
    };speak('Ya vas llegando, estás a punto de marcar un gol, culmina la siguiente actividad y lo lograrás. En el siguiente reto vas a ir a la cafetería, tienes 15 mil pesos; puedes comprar una bebida, un paquetico y algo de la dulcería, ten en cuenta que debes gastar todo.');
  }, []);
  

  const handleDragStart = (e, product, type) => {
    e.dataTransfer.setData('product', product);
    e.dataTransfer.setData('type', type);
  };

  const handleDrop = (e) => {
    const product = parseInt(e.dataTransfer.getData('product'));
    const type = e.dataTransfer.getData('type');

    setSelectedProducts((prev) => ({
      ...prev,
      [type]: product,
    }));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const validateSelection = () => {
    const isValid = correctCombinations.some(
      (combo) =>
        combo.bebida === selectedProducts.bebida &&
        combo.paquetico === selectedProducts.paquetico &&
        combo.dulce === selectedProducts.dulce
    );

    if (isValid) {
      const successAudio = new Audio('/audio/success.mp3'); // Ruta relativa al archivo en public/audio
      successAudio.play();
      alert('¡Prueba superada!');
      if (utteranceRef.current) {
      window.speechSynthesis.cancel(); // Detener el audio si se está reproduciendo
    }
      onNext(); // Pasar a la siguiente escena
    } else {
      const failureAudio = new Audio('/audio/error.mp3'); // Ruta relativa al archivo en public/audio
      failureAudio.play();
      alert('Una o más selecciones son incorrectas. Por favor, inténtalo de nuevo.');
      setSelectedProducts({ bebida: null, paquetico: null, dulce: null });
    }
  };

  return (
    <div className="escena5">
      <h1>Selecciona una bebida, un paquetico y un dulce</h1>
      <img
        src={`/image/${avatar}.png`}
        alt="Avatar del Jugador"
        className={`avatar ${avatarPosition}`}
      />
       <div className="dialog-box">
        <img src={guacamayoBG} alt="Guacamayo" className="guacamayoBG" />
        <p>Ya vas llegando, estás a punto de marcar un gol, culmina la siguiente actividad y lo lograrás. En el siguiente reto vas a ir a la cafetería, tienes 15 mil pesos; puedes comprar una bebida, un paquetico y algo de la dulcería, ten en cuenta que debes gastar todo.</p>
      </div>
      <div className="estantes">
        <div className="estante">
          <div className='textoObjetos'>Bebidas</div>
          {bebidas.map((bebida) => (
            <div
              key={bebida}
              className="producto"
              draggable
              onDragStart={(e) => handleDragStart(e, bebida, 'bebida')}
            >
              {bebida}
            </div>
          ))}
        </div>
        <div className="estante">
         <div className='textoObjetos'>Paqueticos</div>
          {paqueticos.map((paquetico) => (
            <div
              key={paquetico}
              className="producto"
              draggable
              onDragStart={(e) => handleDragStart(e, paquetico, 'paquetico')}
            >
              {paquetico}
            </div>
          ))}
        </div>
        <div className="estante">
          <div className='textoObjetos'>Dulces</div>
          {dulces.map((dulce) => (
            <div
              key={dulce}
              className="producto"
              draggable
              onDragStart={(e) => handleDragStart(e, dulce, 'dulce')}
            >
              {dulce}
            </div>
          ))}
        </div>
      </div>

      <div
        className="canastilla"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div className='textoObjetos'>Canastilla</div>
        <p>Bebida seleccionada: {selectedProducts.bebida || 'Ninguna'}</p>
        <p>Paquetico seleccionado: {selectedProducts.paquetico || 'Ninguna'}</p>
        <p>Dulce seleccionado: {selectedProducts.dulce || 'Ninguna'}</p>
      </div>

      <button onClick={validateSelection}>Validar selección</button>
    </div>
  );
}

export default Escena5;
